import React from 'react'
import { DropzoneArea } from 'material-ui-dropzone';
const CustomDropzone = ({filesLimit=50, maxFiles = 50,acceptedFiles,onChange,componentData,attachmentKey,attachedDoc}) => {
    const attachedKey = attachmentKey && componentData ? componentData[attachmentKey] : componentData["attachments"]
    const updatedFileLimit = componentData?.id ? filesLimit -attachedDoc?.length - attachedKey?.length : filesLimit
    return (
        <div> <DropzoneArea
            filesLimit={(filesLimit - (componentData["attachments"]?.length || 0))}
            acceptedFiles={acceptedFiles ? acceptedFiles :['.xls', '.csv', '.xlsx', '.docx', '.pdf', '.jpg', '.jpeg', '.png']}
            showPreviewsInDropzone={false}
            showPreviews={true}
            maxFileSize={5000000000000}
            useChipsForPreview
            dropzoneText={<p style={{ fontSize: '12px' }}>Drag & Drop Documents OR Click Here</p>}
            maxWidth="sm"
            showAlerts={['error', 'info']}
            onChange={onChange}
            alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' }, style:{marginTop:'30px'} }}
        >
        </DropzoneArea>
            <h6 style={{ marginTop: '5px', fontSize: '11px' }}>
                <span><b>Note:</b>Only pdf, docx, csv, xls, xlsx, jpg, jpeg and png files are supported (Maximum Upto {maxFiles} files.)</span>
                <br />
                {componentData?.id ? <>
                     Files uploaded <b style={{ font: 'menu' }}>({attachedKey?.length || 0})</b>.  
                     {updatedFileLimit<0 ? <span > You have exceeded file limit, Please remove  <b style={{ font: 'menu',color:"red" }}>({Math.abs(updatedFileLimit)})</b> files.</span> : <span> Files remaining <b style={{ font: 'menu' }}>({updatedFileLimit})</b>.</span>}
                </> : null}
            </h6>     
        </div>
    )
}

export default CustomDropzone