import * as actions from '../actions';
import { globalGetService, globalPostService, globalPutService, globalDeleteService } from '../../../../globalServices/';
import { toastFlashMessage } from '../../../../utils/';
import { addLeaseRental } from '../../contractAddObject';
import { checkApiStatus } from '../../../../utils_v2';

export const fetchRentalAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.LEASE_RENTAL_LOADER,
      payload: true
    });
    globalGetService('/contracts/' + params.contract_slug  + '/rental-rates/',{})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.RENTAL_INFO,
          payload: response.data.data
        })
      }
      dispatch({
        type: actions.LEASE_RENTAL_LOADER,
        payload: false
      });
    })
  }
}


export const fetchRevisionHistoryAc = (id) => {
  return (dispatch) => {
    dispatch({
      type: actions.LEASE_RENTAL_LOADER,
      payload: true
    });
    globalGetService('audit/contracts/ctrentalraterequest/?action=1&object_id='+id,{})
    .then(response => {
      dispatch({
        type: actions.LEASE_RENTAL_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.RENTAL_REVISION_HISTORY,
          payload: response.data.data
        });
      }
    });
  }
}


export const rentalInvoiceEditAc = (params, data) => {
  return(dispatch) => {
    dispatch({
      type: actions.LEASE_RENTAL_LOADER,
      payload: true
    });
    let newContractObj = Object.assign({}, data);
    // newContractObj = {
    //   ...newContractObj,
    //   asset_id: data.asset && data.asset.id ? data.asset.id:null,
    //   lessor_id: data.lessor && data.lessor.id ?  data.lessor.id : null,
    //   lessee_id: data.lessee && data.lessee.id ? data.lessee.id:null,
    //   operator_id: data.operator ? data.operator.id:null,
    //   lessor_portfolio :data.lessor_portfolio ? data.lessor_portfolio.id: null
    // }
    // delete newContractObj['asset'];
    // delete newContractObj['lessor'];
    // delete newContractObj['lessee'];
    // delete newContractObj['operator'];
    globalPutService('contracts/'+params.contract_slug +'/rental-invoice-config/', newContractObj)
    .then(response => {
      dispatch({
        type: actions.LEASE_RENTAL_LOADER,
        payload: false
      });
      if(response.data.statusCode ==  200){
        setTimeout(()=>{window.location.reload()},500)
        dispatch({
          type: actions.RENTAL_INVOICE_EDIT,
          payload : response.data.data
        });
        dispatch ({
          type: actions.TOGGLE_RENTAL_INVOICE_SIDE_BAR,
          payload: false
        })
        toastFlashMessage(response.data.message, 'success');
      }
    })
  }
}



export const leaseRentalCrudAc = (params, id, type) => {

  return(dispatch) => {
    if(type == 'add'){
      dispatch({
        type: actions.LEASE_RENTAL_CRUD,
        payload: {type: type, data:addLeaseRental , flag: true }
      });
    }else{
      dispatch({
        type: actions.LEASE_RENTAL_LOADER,
        payload: true
      });
      globalGetService('contracts/'+params.contract_slug+'/rental-rates/' + id + '/',{})
      .then(response => {
        dispatch({
          type: actions.LEASE_RENTAL_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.LEASE_RENTAL_CRUD,
            payload: {type: 'view', data: response.data.data, flag: true }
          });
          if(response.data.data.rental_type.value == 2){
            if(response.data.data.rental_payment_schedule !=null && response.data.data.rental_payment_schedule.length>0){
              dispatch({
                type: actions.LEASE_RENTAL_SHEET_SUCCESS,
                payload:response.data.data.rental_payment_schedule
              });
              dispatch({
                type: actions.LEASE_RENTAL_PATH,
                payload:response.data.data.path
              });
            }
          }
        }
      })
    }
  }
}

export const uploadSheetAc = (formdata, params) => {
  return(dispatch) => {
    dispatch({
      type: actions.SHEET_LOADER,
      payload: true
    });
   globalPostService('/contracts/'+params.contract_slug+'/rental-file-upload/',formdata)
     .then(response => {
      dispatch({
        type: actions.SHEET_LOADER,
        payload: false
        });
    if(response.data.statusCode == 200){
        dispatch({
          type: actions.LEASE_RENTAL_SHEET_SUCCESS,
          payload: response.data.data.rental_payment_schedule
        });
        dispatch({
          type: actions.LEASE_RENTAL_PATH,
          payload:response.data.data.path
        });
       }
     })
  }
}

export const leaseRentalAddEditAc = (params, data, type) => {
  let newData = data
  if([3,4,5].includes(newData.rental_type)){
  newData.pbh_condition = newData.pbh_condition.map(rCond => {
      let item = rCond
      item = {...item, min: item.min === '' ? 0 : item.min, max: item.max === '' ? 0 : item.max}
      return item
    })
  }
  return (dispatch) => {
    dispatch({
      type: actions.LEASE_RENTAL_LOADER,
      payload: true
    });
    if(type == 'add'){

      globalPostService('contracts/'+params.contract_slug+'/rental-rates/', newData)
      .then(response => {
        dispatch({
          type: actions.LEASE_RENTAL_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch(fetchRentalAc(params))
          dispatch({
            type: actions.LEASE_RENTAL_CRUD,
            payload: {type: '', data:{} , flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
        }
      })
    }else{

      globalPutService('contracts/'+params.contract_slug+'/rental-rates/'+data.id+'/', newData)
      .then(response => {
        dispatch({
          type: actions.LEASE_RENTAL_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch(fetchRentalAc(params))
          dispatch({
            type: actions.LEASE_RENTAL_CRUD,
            payload: {type: '', data:{} , flag: false }
          });
          toastFlashMessage(response.data.message, 'success');
        }
      })
    }
  }
}

export const deleteRentalLeaseAc = (params, rentalId) => {
  return (dispatch) => {
    dispatch({
      type: actions.LEASE_RENTAL_LOADER,
      payload: true
    });
    globalDeleteService('contracts/'+params.contract_slug+'/rental-rates/'+rentalId+'/' , {})
    .then(response => {
      dispatch({
        type: actions.LEASE_RENTAL_LOADER,
        payload: false
      });
      dispatch({
        type: actions.DELETE_LEASE_RENTAL,
        payload: rentalId
      });
      dispatch({
        type: actions.MODAL_TOGGLE,
        payload: false
      });
      toastFlashMessage(response.data.message, 'success');
    })
  }

}
export const getChartsAccountAc = (query = {}) => {
  return (dispatch) => {
    globalGetService(`/contracts/charts_of_accounts/`, query)
      .then(response => {
        if (checkApiStatus(response)) {
          dispatch({
            type: actions.SET_CHARTS_ACCOUNT_INFO,
            payload: response?.data?.data?.list
          });
        }
      })
      .catch(error => {
        console.error('Error fetching charts of accounts:', error);
      });
  };
};