import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Tooltip, IconButton, Drawer } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { CustomChip, CustomDropzone, DeletePopUp, LabelValueCard, PageLoader, PreviewDocsModal, RevisionHistory } from '../../../../shared_elements';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../../constants';
import { capitalizeFirstLetter, checkApiStatus, getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { fieldValidation, dateRangeValidation } from '../../../../utils_v2/formValidation';
import { addEditQfdMntEventsApi, getQfdMntEventsApi } from '../apiServices';
import { errorCode, addQfdMntEvent } from '../';
import { addAttachmentApi, deleteAttachmentApi } from '../../../../shared_elements/apiServices';
class QfdMntEventCRU extends Component{
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      pageLoader: false,
      formSubmitLoader:false,
      mode:'',
      qfdMntEvent: {},
      error:{},
      previewDocs: '',
      deleteAttachmentModal: false, 
    }
    this.addEditQfdMntEventsApi = addEditQfdMntEventsApi.bind(this);
    this.getQfdMntEventsApi = getQfdMntEventsApi.bind(this);
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      qfdMntEvent: {
        ...prevState.qfdMntEvent,
        [keyParam]:data
      }
    }))
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]:message
      }
    }))
  }
  toggleFnc = (key,status)=>{
    this.setState({[key] : status})
  }
  getDeleteResponse = () => {
    const { qfdMntEvent, deleteItem } = this.state
    const updatedAttachments = qfdMntEvent?.attachments?.filter(
      attachment => attachment.uuid !== deleteItem?.uuid
    );
    this.setState(prevState => ({
      ...prevState,
      qfdMntEvent: {
        ...prevState.qfdMntEvent,
        attachments: updatedAttachments
      }
    }))
    this.props.getResponseBack()
  }
  addEditQfdMntEventFn = () => {
    const { qfdMntEvent } = this.state;
    let validationInputs = {
      reserve_account:errorCode['reserve_account'][fieldValidation({...errorCode['reserve_accountObj'], fieldval: qfdMntEvent.reserve_account && qfdMntEvent.reserve_account.id ? qfdMntEvent.reserve_account.id.toString():'' })],
      qualified_items:errorCode['qualified_items'][fieldValidation({...errorCode['qualified_itemsObj'], fieldval: qfdMntEvent.qualified_items})],
    };
    if(qfdMntEvent.start_date && qfdMntEvent.start_date){
      validationInputs = {
        ...validationInputs,
        start_date: dateRangeValidation(qfdMntEvent.start_date, qfdMntEvent.end_date, 30)
      }
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      if (this.state.attachedDoc?.length+qfdMntEvent?.attachments?.length>50) {
        this.props.enqueueSnackbar('Only 50 attachment can be attached.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        return; 
      }
      this.setState({formSubmitLoader:true});
      let payload = Object.assign({}, qfdMntEvent)
      payload = {
        ...payload,
        'reserve_account_id': qfdMntEvent.reserve_account.id
      }
      this.addEditQfdMntEventsApi(this.props, payload)
      .then(response => {
        this.setState({formSubmitLoader:false});
        if(checkApiStatus(response)){
          this.setState({modal:false, mode:'', qfdMntEvent:{}, error:{}});
          if (this.state.attachedDoc?.length > 0) {
            addAttachmentApi(response?.data, this.props, this.state.attachedDoc, "17", this.toggleFnc);
          } else {
            this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            this.props.getResponseBack();
          }
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }else{
      this.setState({error:validationInputs});
    }
  }
  render(){
    const { modal, formSubmitLoader, mode, qfdMntEvent, error, pageLoader } = this.state;
    const { id, m, reserveAccounts, contractInfo } = this.props;
    return(
      <Fragment>
        {pageLoader ? <PageLoader /> : null}
        { id ?
          <span onClick={() => this.getQfdMntEventsApi(this.props, id, m)}><Tooltip title="View"><VisibilityIcon color='primary' fontSize='small' style={{cursor:'pointer'}}/></Tooltip></span>:
          contractInfo?.hdlineInfo?.archived_asset ?
          null:
          <Button onClick={() => this.setState({modal:true, qfdMntEvent:addQfdMntEvent, mode:'add'})} variant="contained" color="primary"><AddIcon fontSize='small'/>Add Qualified Maintenance Event</Button>
        }
        { modal ?
          <Drawer
            open={modal}
            anchor="right"
            onClose={() => this.setState({modal:false, mode:'', qfdMntEvent:{}, error:{}})}
          >
            <div style={{width:'670px'}} className="drawer-container">
              <div className="drawer-header" alignItems='center'>
                <span className="left-blk">{`${capitalizeFirstLetter(mode)} Qualified Maintenance Event`}</span>
                <CloseIcon className="close-icon" onClick={()=> this.setState({modal:false})}/>
                { mode !== 'add' ?
                  <ul className="list-inline right-blk">
                    <li className="list-inline-item">
                      <RevisionHistory
                        url='audit/contracts/ctqualifiedmaintenanceevents/'
                        queryParams={{action:1, object_id:id}}
                        buttonType={false}
                      />
                    </li>
                    <li className="list-inline-item">
                      {contractInfo?.hdlineInfo?.archived_asset == false && checkPermission('contracts','qualified_maintenance_event', 'U') && mode === 'view' && <span className="cta" onClick={() => this.setState({mode:'edit'})}><EditIcon className="edit-icon"/>Edit</span>}
                      {mode !== 'view' && <span className="cta" onClick={() => this.setState({mode:'view'})}><VisibilityIcon className="view-icon"/>View</span>}
                    </li>
                  </ul>:null
                }
              </div>
              <div className="drawer-body" style={{position : "sticky"}}>
                <Grid container spacing={2}>
                  { mode === 'view' ?
                    <LabelValueCard md={6} xs={12} label='Reserve Account' value={qfdMntEvent.reserve_account && qfdMntEvent.reserve_account.name ? qfdMntEvent.reserve_account.name:'--'} /> :
                    <Grid item xs={12} md={12}>
                      <Autocomplete
                        options = {reserveAccounts}
                        getOptionLabel={option => option.name}
                        id="reserve_account"
                        value={qfdMntEvent.reserve_account ? qfdMntEvent.reserve_account : null}
                        onChange={(e, value) => {this.onFieldChange(e, 'reserve_account', value);}}
                        renderInput={params => <TextField required onFocus={(e) => this.updateErrorField('reserve_account', '')} error={error.reserve_account ? true:false} helperText={error.reserve_account? error.reserve_account:''} {...params} label="Reserve Account" placeholder="Select Reserve Account" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={12} xs={12} label='Event' value={qfdMntEvent.event ? qfdMntEvent.event:'--'} /> :
                    <Grid item xs={12} md={12}>
                      <TextField
                        id="event"
                        label="Event"
                        fullWidth
                        margin="normal"
                        multiline
                        rows="4"
                        inputProps={{ maxLength: 255 }}
                        value={qfdMntEvent.event ? qfdMntEvent.event:''}
                        onChange={(e, value) => this.onFieldChange(e, 'event', e.target.value)}
                        InputLabelProps={{shrink: true}}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={12} xs={12} label="Qualified Items" value={ qfdMntEvent.qualified_items ? ( <span dangerouslySetInnerHTML={{ __html: qfdMntEvent.qualified_items.replace(/\n/g, '<br />')}}/>): '--'}/>:
                    <Grid item xs={12} md={12}>
                      <TextField
                        id="qualified_items"
                        label="Qualified Items *"
                        fullWidth
                        margin="normal"
                        multiline
                        rows="4"
                        // inputProps={{ maxLength: 1000 }}
                        value={qfdMntEvent.qualified_items ? qfdMntEvent.qualified_items:''}
                        error={error.qualified_items ? true:false }
                        onChange={(e, value) => this.onFieldChange(e, 'qualified_items', e.target.value)}
                        InputLabelProps={{shrink: true}}
                        helperText={error.qualified_items ? error.qualified_items: ''}
                        onFocus={(e) => this.updateErrorField('qualified_items', '')}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={6} xs={12} label='Validity	Start Date' value={qfdMntEvent.start_date ? moment(qfdMntEvent.start_date).format(displayDateFormatShort):'--'} /> :
                    <Grid item xs={12} md={6}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                           margin="normal"
                           id="start_date"
                           label="Validity	Start Date"
                           format={fieldDateFormat}
                           value={qfdMntEvent.start_date ? moment(qfdMntEvent.start_date):null}
                           onChange={(data, value) => this.onFieldChange(value, 'start_date', moment(data).format(backendDateFormat))}
                           fullWidth
                           inputProps={{readOnly: true}}
                           clearable={true}
                           error={error.start_date ? true:false }
                           helperText={ error.start_date ? error.start_date:''}
                           InputLabelProps={{shrink: true}}
                           onFocus={(e) => this.updateErrorField('start_date', '')}
                         />
                        </MuiPickersUtilsProvider>
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={6} xs={12} label='Validity End Date' value={qfdMntEvent.end_date ? moment(qfdMntEvent.end_date).format(displayDateFormatShort):'--'} /> :
                    <Grid item xs={12} md={6}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                           margin="normal"
                           id="end_date"
                           label="Validity End Date"
                           format={fieldDateFormat}
                           value={qfdMntEvent.end_date ? qfdMntEvent.end_date:null}
                           onChange={(data, value) => this.onFieldChange(value, 'end_date', moment(data).format(backendDateFormat))}
                           fullWidth
                           inputProps={{readOnly: true}}
                           clearable={true}
                           InputLabelProps={{shrink: true}}
                         />
                        </MuiPickersUtilsProvider>
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={12} label='Remarks' value={qfdMntEvent.remarks ? ( <span dangerouslySetInnerHTML={{ __html: qfdMntEvent.remarks.replace(/\n/g, '<br />')}}/>):'--'} /> :
                    <Grid item xs={12}>
                      <TextField
                        id="remarks"
                        label="Remarks"
                        fullWidth
                        margin="normal"
                        multiline
                        rows="4"
                        // inputProps={{ maxLength: 255 }}
                        value={qfdMntEvent.remarks ? qfdMntEvent.remarks:''}
                        onChange={(e, value) => this.onFieldChange(e, 'remarks', e.target.value)}
                        InputLabelProps={{shrink: true}}
                      />
                    </Grid>
                  }
                  {mode === 'view' ?
                    <div style={{ marginLeft: '8px', marginBottom: '20px' }}>
                      <p style={{ fontSize: '14px', color: 'rgb(160, 160, 160)', fontFamily: "Conv_IBMPlexSans-Regular" }}>Attachment(s) {qfdMntEvent?.attachments?.length>0 ? `[Count : ${qfdMntEvent?.attachments?.length}]` : null}</p>
                      {qfdMntEvent?.attachments?.length ? qfdMntEvent?.attachments && qfdMntEvent?.attachments.map((file, index) => (
                        <CustomChip
                          style={{ marginRight: '4px', marginTop: '7px'}}
                          title={file.name}
                          index={index}
                          label={file.name.substring(0, 14) + '...'}
                          onClick={(e) => { e.preventDefault(); e.stopPropagation();  this.setState({ previewDocs: file }) }}
                        />
                      )) : '--'}
                    </div>
                    :
                    (
                      <Grid item xs={12} style={{ marginBottom: '5px' }} className='drag-drop'>
                        <p style={{ fontSize: '13px', color: 'rgb(160, 160, 160)', fontFamily: "Conv_IBMPlexSans-Regular", marginLeft: '8px' }}>Attachment(s)</p>
                        <CustomDropzone
                          onChange={(files) => this.setState({ attachedDoc: files })}
                          componentData={qfdMntEvent}
                          attachedDoc = {this.state.attachedDoc}
                        >
                        </CustomDropzone>
                        {qfdMntEvent?.attachments && qfdMntEvent?.attachments.map((file, index) => (
                          <CustomChip
                            style={{ marginRight: '4px', marginTop: '7px'}}
                            title={file.name}
                            index={index}
                            label={file.name.substring(0, 14) + '...'}
                            onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ previewDocs: file })}}
                            onDelete={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ deleteAttachmentModal: true, deleteItem: file }); }}
                          />
                        ))}
                      </Grid>)}
                </Grid>
              </div>
              <div className="drawer-footer">
                { mode !== 'view' ?
                  <Button onClick={this.addEditQfdMntEventFn} color="primary" variant="contained" disabled={formSubmitLoader}>
                    { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save Changes' }
                  </Button>:null
                }
                <Button onClick={() => this.setState({modal:false, mode:'', qfdMntEvent:{}, error:{}})} color="primary" style={mode == 'view' ? {marginLeft:'auto', paddingLeft:'0px'}:{marginLeft:'8px'}}>Cancel</Button>
              </div>
            </div>
          </Drawer>:null
        }

      {
        this.state.previewDocs ? <PreviewDocsModal previewDocs={this.state.previewDocs} toggleModalFn={() => this.setState({ previewDocs: '' })}/> : null
      }
      {this.state.deleteAttachmentModal ?
        <DeletePopUp
          modal={this.state.deleteAttachmentModal}
          toggleModalFn={() => this.setState({ deleteAttachmentModal: false })}
          title="Delete Attachment"
          content={<h4>Are you sure you want to delete?</h4>}
             deleteRecordFn={() => deleteAttachmentApi(this.state.deleteItem,this.toggleFnc,this.getDeleteResponse)}
        /> : null
      }
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(QfdMntEventCRU));
