import React, { useEffect, useState } from 'react'
import { ContractSideBar, Header } from '../../../../applications/contracts/Elements'
import { ContractWrapper, LeaseDetialTab } from '../../../Elements'
import { assetsType } from '../../../../constants';
import { browserHistory } from 'react-router';
import { checkApiStatus, checkPermission } from '../../../../utils_v2';
import OperationCRU from '../components/OperationCRU';
import { globalDeleteService, globalGetService } from '../../../../globalServices';
import { STableLoader } from '../../../../shared_elements/loaders';
import { DeletePopUp, EmptyCollection, PageLoader, TableListComp } from '../../../../shared_elements';
import { TableCell, TableRow } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import ReadMoreInline from '../../../../shared_elements/components/ReadMoreInline';
import VisibilityIcon from '@material-ui/icons/Visibility';

const heads = [
  { label: 'Region', key: 'region' },
  { label: 'Escalation Percentage', key: 'escalation' },
  { label: 'Status', key: 'status' },
  { label: 'Action', sortOption: false, actionCell: true }
]
const RegionListing = ({ item, setModal, setDeleteModal }) => {
  return (
    <TableRow hover tabIndex={-1} >
      <TableCell style={{width:"400px"}} >{<ReadMoreInline maxLength='100' text={item?.name} />}</TableCell>
      <TableCell>{item.escalation_percentage ? item.escalation_percentage : '--'}</TableCell>
      <TableCell><b> {item.status ? 'Enabled': 'Disabled'}</b></TableCell>
      <TableCell align='right'>{<>
        <VisibilityIcon color='primary' fontSize='small' style={{ cursor: 'pointer',width: '20px', marginRight: '5px' }} onClick={() => setModal({ flag: true, data: item, mode:'view' })}  />
        <Edit onClick={() => setModal({ flag: true, data: item })} color='primary' style={{ width: '20px', marginRight: '5px', cursor: "pointer" }} />
        <Delete onClick={() => setDeleteModal({ flag: true, data: item })} color='secondary' style={{ width: '20px', cursor: "pointer" }} />
      </>}</TableCell>
    </TableRow>
  )
}
export default function RegionEscalation(props) {
  const [isloading, setLoading] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [regionList, setRegionList] = useState([]);
  const [modal, setModal] = useState({ flag: false, data: null });
  const [deleteModal, setDeleteModal] = useState({ flag: false, data: null });
  const {enqueueSnackbar} = useSnackbar ();
  useEffect(() => {
    getRegionList('skeleton');
  }, [])

  const changeMsn = (evt) => {
    let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts';
    browserHistory.push(url);
  }
  const getRegionList = (loader) => {
    if (loader === 'skeleton') {
      setSkeletonLoader(true);
    } else {
      setLoading(true);
    }
    globalGetService(`contracts/${props.params.contract_slug}/regions/`,)
      .then(response => {
        if (loader === 'skeleton') {
          setSkeletonLoader(false);
        } else {
          setLoading(false);
        }
        if (checkApiStatus(response)) {
          setRegionList(response.data?.data);
        }
      })
  }
  const deleteRegionFn = () => {
    globalDeleteService(`contracts/${props.params.contract_slug}/regions/${deleteModal?.data?.id}/`)
      .then(response => {
        if (checkApiStatus(response)) {
          enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          setDeleteModal({ flag: false, data: null });
          getRegionList();
        }
      })
  }

  return (
    <div className="contract-container">
      <Header type='brief' headingText="Lease Management" params={props.params} changeMsn={changeMsn} exportContract={props.exportContract} />
      <ContractSideBar application="Operation Region Escalation" type="brief" params={props.params} />
      <div className="contract-content-blk">
        <LeaseDetialTab url='region-escalation' primaryTab='basic-betails' secondaryTab='region-escalation' />
        <ContractWrapper
          revision={true}
          url="audit/contracts/ctregionescalation/"
          queryParams={{ action: '0,1', contract: props.params.contract_slug }}
          buttonText={checkPermission('contracts', 'party', 'C') ? <OperationCRU getResponseBack={getRegionList} props={props} setModal={setModal} modal={modal} /> : null}
        >
          {isloading ? <PageLoader /> : null}
          {skeletonLoader ?
            <STableLoader count={8} />
            :
            <TableListComp
              heads={heads}
              data={regionList?.map((item, index) => <RegionListing setDeleteModal={setDeleteModal} setModal={setModal} key={index} item={item} />)}
              noRecord={regionList?.length ? null :
                isloading ? null :
                  <EmptyCollection
                    title="No records found"
                  />
              }
            />
          }
        </ContractWrapper>
      </div>
      { deleteModal?.flag ?
          <DeletePopUp
            modal={deleteModal.flag}
            toggleModalFn={() => setDeleteModal({ flag: false, data: null })}
            title="Delete"
            content={<h4>Are you sure you want to delete?</h4>}
            deleteRecordFn={() => deleteRegionFn(deleteModal?.data)}
          />
          : null
      }
    </div>
  )
}
