
import React, { useState } from 'react';
import { TableRow, TableCell, Tooltip, Chip,Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText } from '@material-ui/core';
import DeliveryCondCRU from './DeliveryCondCRU';
import { checkPermission } from '../../../../utils_v2';
import DeleteIcon from '@material-ui/icons/Delete';
export default function DeliveryCndList({item, previewDetail, getResponseBack, contractClauseList, contractInfo,deleteDeliveryCond}){
  const [showMore, setShowMore] = useState({});
  const [previewDocs, setPreviewDocs] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [openAttachment, setOpenAttachment] = useState(false);
  const attachmentsToShow = Array.isArray(item?.pooling_language) ? (showAll ? item.pooling_language : item.pooling_language.slice(0, 2)) : [];
  const toggleDescription = (id) => {
    setShowMore({
      ...showMore,
      [id]: !showMore[id],
    });
  };
  return(
    <>
    <TableRow hover tabIndex={-1}>
      <TableCell style={{maxWidth:'150px'}}>{item.asset_conditions_for_contract.name}</TableCell>
      <TableCell style={{width:'300px'}}>
      {item?.terms && item?.terms?.length > 200 ? (
          <div style={{ width: '300px' }}>
            {showMore[item?.id] && showMore[item?.id] ? (
              <span>
                <span dangerouslySetInnerHTML={{ __html: item?.terms.replace(/\n/g, '<br />') }} />
                <a href='#' style={{ color: '#0E7FE1', fontSize: '14px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }} >
                  less
                </a>
              </span>
            ) : (
              <span>
                <span dangerouslySetInnerHTML={{ __html: item?.terms.substring(0, 190).replace(/\n/g, '<br />') }} />
                <a href='#' style={{ color: '#0E7FE1', fontSize: '14px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }} >
                  ..more
                </a>
              </span>
            )}
          </div>
        ) : (
          <div>
                <span dangerouslySetInnerHTML={{ __html: item?.terms.replace(/\n/g, '<br />') }} />
          </div>
        )}  
      </TableCell>
      <TableCell>{item.actual ? ( <span dangerouslySetInnerHTML={{ __html: item.exceptions_done.replace(/\n/g, '<br />')}}/>):'--'}</TableCell>
      <TableCell>{item.exceptions_done ? ( <span dangerouslySetInnerHTML={{ __html: item.exceptions_done.replace(/\n/g, '<br />')}}/>):'--'}</TableCell>
        <TableCell>
          {attachmentsToShow?.length ? attachmentsToShow?.map((file, index) => (
            <Tooltip key={index} title={`${file.name}`} arrow placement="top">
              <Chip
                style={{ marginRight: '4px', marginTop: item?.pooling_language?.length > 2 ? '7px' : '', width: '50px', height: '15px', fontSize: '9px' }}
                variant="outlined"
                size="small"
                label={file.name.substring(0, 14) + '...'}
                clickable
                color="primary"
                onClick={(e) => { e.preventDefault(); setPreviewDocs(file); }}
              />
            </Tooltip>
          )) : '--'}
          {item?.pooling_language?.length > 2 && (
            <div style={{ marginTop: '10px' }}>
              <Chip
                label={openAttachment ? 'Show Less' : 'Show More'}
                clickable
                color='primary'
                onClick={() => setOpenAttachment(!openAttachment)}
                style={{ cursor: 'pointer', height: '15px', fontSize: '9px' }}
              />
            </div>
          )}
        </TableCell>
      <TableCell style={{textAlign:'right'}}>
        <DeliveryCondCRU contractInfo={contractInfo} id={item.id} contractClauseList={contractClauseList} getResponseBack={getResponseBack} m="V" />
        {checkPermission('contracts','delivery_conditions', 'D') ? <DeleteIcon fontSize='small' color='secondary' style={{cursor:'pointer'}} onClick={()=>deleteDeliveryCond(item.id)}/>: null}
        </TableCell>
    </TableRow>
    {previewDocs ? (
        <Dialog open={true} fullScreen >
          <DialogTitle>{previewDocs.name}</DialogTitle>
          <DialogContent style={{ height: '80vh' }}>
            {previewDocs.extension === 'csv' ? (
              <object
                data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/xls_viewer/index.html?file=${encodeURIComponent(previewDocs.url)}`} style={{ width: '100%', height: '100%' }}/>
            ) : ['xls', 'xlsx', 'docx'].includes(previewDocs.extension.toLowerCase()) ? (
              <iframe
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(previewDocs.url)}`} width="100%" height="100%" frameBorder="0"/>
            ) : ['jpg', 'jpeg', 'png'].includes(previewDocs.extension.toLowerCase()) ? (
              <img src={previewDocs?.url} alt="Attachment" style={{ width: '100%', height: 'auto' }} />
            ) : previewDocs.extension === 'pdf' ? (
              <object type="text/html" data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${encodeURIComponent(previewDocs.url)}`} style={{ width: '100%', height: '100%' }}/>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={(e) => { window.open(previewDocs.url); e.stopPropagation(); }}>Download</Button>
            <Button variant="contained" color="primary" onClick={()=> setPreviewDocs(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {openAttachment &&
        <Dialog open={openAttachment} className='mou-dialog'>
        <DialogTitle>Attachment(s) [Count : {item?.pooling_language?.length}]</DialogTitle>
        <DialogContent dividers style={{minWidth:"500px"}}>
          <List style={{ border: "1px solid #ede8e8", padding:"0px"}}>
          {item?.pooling_language?.map((file, index) => (
            <ListItem button key={index} style={{ backgroundColor: index % 2 === 0 ? '#ebeff6' : '#fff' }} onClick={() => setPreviewDocs(file)}>
              <ListItemText primary={file.name}/>
            </ListItem>
          ))}
        </List>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='primary' size='small' onClick={() => setOpenAttachment(false)}>Close</Button>
        </DialogActions>
      </Dialog>}
    </>
  )
}
