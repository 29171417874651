import React, { useState } from 'react';
import { TableRow, TableCell, Tooltip, Chip,  } from '@material-ui/core';
import InsuranceCRU from './InsuranceCRU';
import DeleteIcon from '@material-ui/icons/Delete';
import NumericLabel from 'react-pretty-numbers';
import { getCurrencyFormat, checkPermission } from '../../../../utils_v2';
import moment from 'moment';
import { displayDateFormatShort } from '../../../../constants';
import { AttachmentsRow } from '../../components';

export default function InsuranceList({item, previewDetail, pltConstants, getResponseBack, contractInfo,deleteInsurance}){
  return(
    <TableRow hover tabIndex={-1} style={{position:'relative'}}>
      <TableCell>{item.insurance_type.label}</TableCell>
      <TableCell>{item.insurance_number}</TableCell>
      <TableCell>{item.insurer}</TableCell>
      <TableCell>{item.insuree}</TableCell>
      <TableCell>
        {item.policy_start_date ? moment(item.policy_start_date).format(displayDateFormatShort):''}
        -
        {item.policy_end_date ? moment(item.policy_end_date).format(displayDateFormatShort):''}
      </TableCell>
      <TableCell>{item.insurance_amount? <NumericLabel params={getCurrencyFormat()}>{item.insurance_amount}</NumericLabel>:'--'}</TableCell>
       <TableCell>{item?.attachments?.length> 0 ?<AttachmentsRow attachmentsData={item?.attachments}/>:"--"}</TableCell> 
      <TableCell>
        <InsuranceCRU id={item.id} m="V" pltConstants={pltConstants} getResponseBack={getResponseBack} contractInfo={contractInfo}/>
        {checkPermission('contracts','insurance', 'D') ? <DeleteIcon fontSize='small' color='secondary' style={{cursor:'pointer'}} onClick={()=>deleteInsurance(item.id)}/>: null}
      </TableCell>
    </TableRow>
  )
}
