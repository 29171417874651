import React, { Fragment } from 'react';
import {Tabs,Tab} from '@material-ui/core';
import { browserHistory, withRouter, Link } from 'react-router';
import backArrowIcon from '../../shared_elements/assets/img/back_arrow.svg';
import { checkPermission } from '../../utils_v2'
const LeaseDetialTab = ({params, url, primaryTab='basic-betails', secondaryTab='headlines'}) => {
  const redirectTechnicalTab = () => {
    if(checkPermission('contracts','eol_options','R')){
      browserHistory.push(`/${params.type}/${params.aircraft_slug}/contract/${params.contract_slug}/lease-option`)
    }else if(checkPermission('contracts','induction_conditions','R')){
      browserHistory.push(`/${params.type}/${params.aircraft_slug}/contract/${params.contract_slug}/induction-condition`)
    }else if(checkPermission('contracts','delivery_conditions','R')){
      browserHistory.push(`/${params.type}/${params.aircraft_slug}/contract/${params.contract_slug}/delivery-condition`)
    }else if(checkPermission('contracts','return_conditions','R')){
      browserHistory.push(`/${params.type}/${params.aircraft_slug}/contract/${params.contract_slug}/return-condition`)
    }else if(checkPermission('contracts','qualified_maintenance_event','R')){
      browserHistory.push(`/${params.type}/${params.aircraft_slug}/contract/${params.contract_slug}/qualified-maintenance`)
    }
  }
  return(
    <div className="lease-details-tabs">
      <Link to={"/" +params.type+ "/" +params.aircraft_slug+ "/contracts"} style={{color:'#bd10e0', fontSize:'16px', fontWeight:'700'}}>
        <img width="16" src={backArrowIcon} alt="Back Icon" style={{marginRight:'3px'}}/> Back to Lease(s)
      </Link>
      <Tabs
        value={primaryTab}
        onChange={(event, newValue) => newValue == 'basic-betails' ? browserHistory.push(`/${params.type}/${params.aircraft_slug}/contract/list/${params.contract_slug}`):redirectTechnicalTab()}
        indicatorColor="primary"
        textColor="primary"
        centered
        variant={'fullWidth'}
      >
        <Tab label="Basic Details" value="basic-betails" />
        {checkPermission('contracts','eol_options','R') || checkPermission('contracts','induction_conditions','R') || checkPermission('contracts','return_conditions','R') || checkPermission('contracts','delivery_conditions','R') || checkPermission('contracts','qualified_maintenance_event','R') ?
          <Tab label="Technical Details" value="technical-details" />:null
        }
      </Tabs>
      { primaryTab === 'basic-betails' &&
        <div>
          <Tabs
            value={url}
            onChange={(event, newValue) => newValue === 'headlines' ? browserHistory.push(`/${params.type}/${params.aircraft_slug}/contract/list/${params.contract_slug}`) :browserHistory.push(`/${params.type}/${params.aircraft_slug}/contract/${params.contract_slug}/${newValue}`)}
            indicatorColor="primary"
            scrollButtons={'auto'}
            textColor="primary"
            centered
            variant="scrollable"
          >
            <Tab label="Headlines" value="headlines" />
            <Tab label="Operating Region Escalation " value="region-escalation" />
            {checkPermission('contracts','working_groups','R') ? <Tab label="Working Groups" value="working-group"/>:null}
            {checkPermission('contracts','party','R') ? <Tab label="Party" value="parties"/>:null}
            {checkPermission('contracts','cape_town_convention','R') ? <Tab label="Cape Town Convention" value="cape-town-convention"/>:null}
            {checkPermission('contracts','sublease','R') ? <Tab label="Sublease" value="sub-lease"/>:null}
            {checkPermission('contracts','obligations','R') ? <Tab label="Obligations" value="obligations"/>:null}
            {checkPermission('contracts','security_deposit','R') ? <Tab label="Security Deposit" value="security-deposit"/>:null}
            {checkPermission('contracts','insurance','R') ? <Tab label="Insurance" value="insurance"/>:null}
            {checkPermission('contracts','financing_details','R') ? <Tab label="Financing Details" value="finance-detail"/>:null}
          </Tabs>
        </div>
      }
      {primaryTab === 'technical-details' &&
        <div>
          <Tabs
            value={url}
            onChange={(event, newValue) => browserHistory.push(`/${params.type}/${params.aircraft_slug}/contract/${params.contract_slug}/${newValue}`)}
            indicatorColor="primary"
            textColor="primary"
            centered
            variant={'fullWidth'}
          >
            { checkPermission('contracts','eol_options','R') ? <Tab label="EOL Options" value="lease-option"/>:null }
            { checkPermission('contracts','induction_conditions','R') ? <Tab label="Induction Conditions" value="induction-condition"/>:null}
            { checkPermission('contracts','delivery_conditions','R') ? <Tab label="Delivery Conditions" value="delivery-condition"/>:null}
            { checkPermission('contracts','return_conditions','R') ? <Tab label="Return Conditions" value="return-condition"/>:null}
            { checkPermission('contracts','qualified_maintenance_event','R') ? <Tab label="Qualified Maintenance Events" value="qualified-maintenance"/> :null}
          </Tabs>
        </div>
      }
    </div>
  )
}
export default withRouter(LeaseDetialTab);
