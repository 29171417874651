import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Tooltip, IconButton, Drawer } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { LabelValueCard, RevisionHistory, PageLoader, AutocompleteCreatable, CustomChip, CustomDropzone, PreviewDocsModal, DeletePopUp } from '../../../../shared_elements';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../../constants';
import { addEditPartyApi, getPartyApi, getRolesListApi } from '../apiServices';
import { checkApiStatus, capitalizeFirstLetter, getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { fieldValidation, dateRangeValidation } from '../../../../utils_v2/formValidation';
import { errorCode } from '../';
import { addAttachmentApi, deleteAttachmentApi } from '../../../../shared_elements/apiServices';
class PartyCRU extends Component {
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      formSubmitLoader: false,
      pageLoader: false,
      party:{},
      error:{},
      mode:'',
      rolesList:[],
      previewDocs: '',
      deleteAttachmentModal: false, 
      attachedDoc : [],
      deleteItem : {}
    }
    this.addEditPartyApi = addEditPartyApi.bind(this);
    this.getPartyApi = getPartyApi.bind(this);
    this.getRolesListApi = getRolesListApi.bind(this);
  }

  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      party: {
        ...prevState.party,
        [keyParam]:data
      }
    }))
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]:message
      }
    }))
  }
  toggleFnc = (key,status)=>{
    this.setState({[key] : status})
  }
  addEditPartyFn = () => {
    const { party, mode } = this.state;
    let validationInputs = {
      role:errorCode['role'][fieldValidation({...errorCode['roleObj'], fieldval: party.role && party.role.name ? party.role.name.toString():'' })],
      name:errorCode['name'][fieldValidation({...errorCode['nameObj'], fieldval: party.name})],
      start_date:errorCode['start_date'][fieldValidation({...errorCode['start_dateObj'], fieldval: party.start_date})],
      end_date:errorCode['end_date'][fieldValidation({...errorCode['end_dateObj'], fieldval: party.end_date})],
    };

    if(party.start_date && party.end_date){
      validationInputs = {
        ...validationInputs,
        start_date: dateRangeValidation(party.start_date, party.end_date, 1)
      }
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      if (this.state.attachedDoc?.length+party?.attachments?.length>50) {
        this.props.enqueueSnackbar('Only 50 attachment can be attached.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        return; 
      }
      this.setState({formSubmitLoader:true});
      let payload = Object.assign({}, party)
      if (mode.includes('edit')){
        payload = {...payload, role: payload.role.id}
      }
      this.addEditPartyApi(this.props, payload)
      .then(response => {
        this.setState({formSubmitLoader:false});
        if(checkApiStatus(response)){
          if (this.state.attachedDoc?.length > 0) {
            addAttachmentApi(response?.data, this.props, this.state.attachedDoc, "12", this.toggleFnc);
          }else{
            this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            this.props.getResponseBack();
          }
          this.setState({modal:false, party:{}, error:{}});
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }else{
      this.setState({error: validationInputs});
    }
  }

  getDeleteResponse = () => {
    const { party, deleteItem } = this.state
    const updatedAttachments = party?.attachments?.filter(
      attachment => attachment.uuid !== deleteItem?.uuid
    );
    this.setState(prevState => ({
      ...prevState,
      party: {
        ...prevState.party,
        attachments: updatedAttachments
      }
    }))
    this.props.getResponseBack()
  }

  render() {
    const { mode, formSubmitLoader, pageLoader, modal, party, error, rolesList } = this.state;
    const { id,  m , contractInfo} = this.props;
    return(
      <Fragment>
        { pageLoader ? <PageLoader />:null}
        { id ?
          <span onClick={() => {this.getPartyApi(this.props, id, m); this.getRolesListApi()}}><Tooltip title="View"><VisibilityIcon color='primary' fontSize='small' style={{cursor:'pointer'}}/></Tooltip></span>
          :
          contractInfo?.hdlineInfo?.archived_asset == false?
          <Button onClick={() => {this.setState({modal:true, party:{}, mode:'add'}); this.getRolesListApi()}} variant="contained" color="primary">Add Party</Button>
        : null}
        <Drawer
          open={modal}
          anchor="right"
        >
          <div className="drawer-container" style={{width:'670px'}}>
            <div className="drawer-header">
              <span className="left-blk">{`${capitalizeFirstLetter(mode)} Party`}</span>
              <CloseIcon className="close-icon" onClick={()=> this.setState({modal:false})}/>
              { mode !== 'add' ?
                <ul className="list-inline right-blk">
                  <li className="list-inline-item">
                    <RevisionHistory
                      url='audit/contracts/ctparties/'
                      queryParams={{action:1, object_id:id}}
                      buttonType={false}
                    />
                  </li>
                  <li className="list-inline-item">
                    {contractInfo?.hdlineInfo?.archived_asset == false ? checkPermission('contracts','party', 'U') && mode === 'view' && <span className="cta" onClick={() => this.setState({mode:'edit'})}><EditIcon className="edit-icon"/>Edit</span>: null}
                    {mode !== 'view' && <span className="cta" onClick={() => this.setState({mode:'view'})}><VisibilityIcon className="view-icon"/>View</span>}
                  </li>
                </ul>:null
              }
            </div>
            <div className="drawer-body" style={{position : "sticky"}}>
              <Grid container spacing={3}>
                { mode === 'view' || mode === 'edit' ?
                  <LabelValueCard md={6} label='Role' value={party.role && party.role.name ? party.role.name:'--'} /> :
                  <Grid item xs={12} md={6}>
                    <AutocompleteCreatable
                      required={true}
                      options={rolesList}
                      paramsKey='role'
                      optionKey="name"
                      label='Role'
                      value={party.role ? party.role : null}
                      onFieldChange={this.onFieldChange}
                      error={error.role ? true : false}
                      helperText={error.role ? error.role : ''}
                      resetErrorKey={() => this.updateErrorField('role', '')}
                    />
                  </Grid>
                }
                { mode === 'view' ?
                  <LabelValueCard md={6} label='Name' value={party.name ? party.name:'--'} /> :
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="name"
                      label="Name"
                      fullWidth
                      margin="normal"
                      value={party.name ? party.name:''}
                      error={error.name ? true:false }
                      InputLabelProps={{shrink: true}}
                      inputProps={{ maxLength: 50 }}
                      helperText={error.name ? error.name: ''}
                      onChange={(e) => this.onFieldChange(e, 'name', e.target.value)}
                      onFocus={(e) => this.updateErrorField('name','')}
                    />
                  </Grid>
                }

                { mode === 'view' ?
                  <LabelValueCard md={12} label='Jurisdiction' value={party.jurisdiction ? party.jurisdiction:'--'} /> :
                  <Grid item xs={12}>
                    <TextField
                      id="jurisdiction"
                      label="Jurisdiction"
                      fullWidth
                      margin="normal"
                      inputProps={{ maxLength: 100 }}
                      value={party.jurisdiction ? party.jurisdiction:''}
                      onChange={(e, value) => this.onFieldChange(e, 'jurisdiction', e.target.value)}
                      InputLabelProps={{shrink: true}}
                      helperText={error.jurisdiction ? error.jurisdiction: ''}
                      error={error.jurisdiction ? true: false}
                      onFocus={(e) => this.updateErrorField('jurisdiction','')}
                    />
                  </Grid>
                }

                { mode === 'view' ?
                  <LabelValueCard md={12} label='Contact Address' value={party.contact_address ? party.contact_address:'--'} /> :
                  <Grid item xs={12}>
                    <TextField
                      id="contact_address"
                      label="Contact Address"
                      fullWidth
                      margin="normal"
                      inputProps={{ maxLength: 100 }}
                      value={party.contact_address ? party.contact_address:''}
                      onChange={(e, value) => this.onFieldChange(e, 'contact_address', e.target.value)}
                      InputLabelProps={{shrink: true}}
                      helperText={error.contact_address ? error.contact_address: ''}
                      error={error.contact_address ? true: false}
                      onFocus={(e) => this.updateErrorField('contact_address','')}
                    />
                  </Grid>
                }

                { mode === 'view' ?
                  <LabelValueCard md={6} label='Concerned Person' value={party.concerned_person_name ? party.concerned_person_name:'--'} /> :
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="concerned_person_name"
                      label="Concerned Person"
                      fullWidth
                      margin="normal"
                      inputProps={{ maxLength: 100 }}
                      value={party.concerned_person_name ? party.concerned_person_name:''}
                      onChange={(e, value) => this.onFieldChange(e, 'concerned_person_name', e.target.value)}
                      InputLabelProps={{shrink: true}}
                      helperText={error.concerned_person_name ? error.concerned_person_name: ''}
                      error={error.concerned_person_name ? true: false}
                      onFocus={(e) => this.updateErrorField('concerned_person_name','')}
                    />
                  </Grid>
                }

                { mode === 'view' ?
                  <LabelValueCard md={6} label='Concerned Person Designation' value={party.concerned_person_designation ? party.concerned_person_designation:'--'} /> :
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="concerned_person_designation"
                      label="Concerned Person Designation"
                      fullWidth
                      margin="normal"
                      inputProps={{ maxLength: 100 }}
                      value={party.concerned_person_designation ? party.concerned_person_designation:''}
                      onChange={(e, value) => this.onFieldChange(e, 'concerned_person_designation', e.target.value)}
                      InputLabelProps={{shrink: true}}
                      helperText={error.concerned_person_designation ? error.concerned_person_designation: ''}
                      error={error.concerned_person_designation ? true: false}
                      onFocus={(e) => this.updateErrorField('concerned_person_designation','')}
                    />
                  </Grid>
                }

                { mode === 'view' ?
                  <LabelValueCard md={6} label='Start Date' value={party.start_date ? moment(party.start_date).format(displayDateFormatShort):'--'} /> :
                  <Grid item xs={12} md={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                         required
                         margin="normal"
                         id="start_date"
                         label="Start Date"
                         format={fieldDateFormat}
                         fullWidth
                         InputLabelProps={{shrink: true}}
                         inputProps={{readOnly: true}}
                         clearable={true}
                         value={party.start_date ? party.start_date:null}
                         error={error.start_date ? true:false }
                         onChange={(data, value) => this.onFieldChange(value, 'start_date', moment(data).format(backendDateFormat))}
                         helperText={error.start_date ? error.start_date: ''}
                         onFocus={(e) => this.updateErrorField('start_date', '')}
                         onOpen={() => this.updateErrorField('start_date', '')}
                       />
                    </MuiPickersUtilsProvider>
                  </Grid>
                }

                { mode === 'view' ?
                  <LabelValueCard md={6} label='End Date' value={party.end_date ? moment(party.end_date).format(displayDateFormatShort):'--'} /> :
                  <Grid item xs={12} md={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        required
                         margin="normal"
                         id="end_date"
                         label="End Date"
                         format={fieldDateFormat}
                         fullWidth
                         InputLabelProps={{shrink: true}}
                         inputProps={{readOnly: true}}
                         clearable={true}
                         value={party.end_date ? party.end_date:null}
                         error={error.end_date ? true:false }
                         onChange={(data, value) => this.onFieldChange(value, 'end_date', moment(data).format(backendDateFormat))}
                         helperText={error.end_date ? error.end_date: ''}
                         onFocus={(e) => this.updateErrorField('end_date', '')}
                         onOpen={() => this.updateErrorField('end_date', '')}
                       />
                      </MuiPickersUtilsProvider>
                  </Grid>
                }

                { mode === 'view' ?
                  <LabelValueCard md={12} label='Remarks' value={party.remarks ?  ( <span dangerouslySetInnerHTML={{ __html: party.remarks.replace(/\n/g, '<br />')}}/>):'--'} /> :
                  <Grid item xs={12}>
                    <TextField
                      id="remarks"
                      label="Remarks"
                      fullWidth
                      margin="normal"
                      multiline
                      rows="4"
                      // inputProps={{ maxLength: 255 }}
                      value={party.remarks ? party.remarks:''}
                      onChange={(e, value) => this.onFieldChange(e, 'remarks', e.target.value)}
                      InputLabelProps={{shrink: true}}
                    />
                  </Grid>
                }
                {mode === 'view' ?
                  <div style={{ marginLeft: '8px', marginBottom: '20px' }}>
                    <p style={{ fontSize: '14px', color: 'rgb(160, 160, 160)', fontFamily: "Conv_IBMPlexSans-Regular" }}>Attachment(s) {party?.attachments?.length>0 ? `[Count : ${party?.attachments?.length}]` : null}</p>
                    {party?.attachments?.length ? party?.attachments && party?.attachments.map((file, index) => (
                      <CustomChip
                        style={{ marginRight: '4px', marginTop: '7px'}}
                        title={file.name}
                        index={index}
                        label={file.name.substring(0, 14) + '...'}
                        onClick={(e) => { e.preventDefault(); e.stopPropagation();  this.setState({ previewDocs: file }) }}
                      />
                    )) : '--'}
                  </div>
                  :
                  (
                    <Grid item xs={12} style={{ marginBottom: '20px' }} className='drag-drop'>
                      <p style={{ fontSize: '13px', color: 'rgb(160, 160, 160)', fontFamily: "Conv_IBMPlexSans-Regular", marginLeft: '8px' }}>Attachment(s)</p>
                      <CustomDropzone
                        onChange={(files) => this.setState({ attachedDoc: files })}
                        componentData= {party}
                        attachedDoc = {this.state.attachedDoc}
                      >
                      </CustomDropzone>
                      {party?.attachments && party?.attachments.map((file, index) => (
                        <CustomChip
                          style={{ marginRight: '4px', marginTop: '7px'}}
                          title={file.name}
                          index={index}
                          label={file.name.substring(0, 14) + '...'}
                          onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ previewDocs: file })}}
                          onDelete={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ deleteAttachmentModal: true, deleteItem: file }); }}
                        />
                      ))}
                    </Grid>)}
              </Grid>
            </div>
            <div className="drawer-footer">
              { mode !== 'view' ?
                <Button onClick={this.addEditPartyFn} color="primary" variant="contained" disabled={formSubmitLoader}>
                  { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save Changes' }
                </Button>:null
              }
              <Button onClick={() => this.setState({modal:false, mode:'', party:{}, error:{}})} color="primary" style={mode == 'view' ? {marginLeft:'auto', paddingLeft:'0px'}:{marginLeft:'8px'}}>Cancel</Button>
            </div>
          </div>
        </Drawer>
        {
        this.state.previewDocs ? <PreviewDocsModal previewDocs={this.state.previewDocs} toggleModalFn={() => this.setState({ previewDocs: '' })}/> : null
      }
      {this.state.deleteAttachmentModal ?
        <DeletePopUp
          modal={this.state.deleteAttachmentModal}
          toggleModalFn={() => this.setState({ deleteAttachmentModal: false })}
          title="Delete Attachment"
          content={<h4>Are you sure you want to delete?</h4>}
          deleteRecordFn={() => deleteAttachmentApi(this.state.deleteItem,this.toggleFnc,this.getDeleteResponse)}
        /> : null
      }
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(PartyCRU));
