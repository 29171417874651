import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import PartyCRU from './PartyCRU';
import { checkPermission } from '../../../../utils_v2';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import { displayDateFormatShort } from '../../../../constants';
import { AttachmentsRow } from '../../components';

export default function PartyList({item, previewDetail, pltConstants, getResponseBack, contractInfo,deletePartylist}){
  return(
    <TableRow hover tabIndex={-1} >
      <TableCell>{item.name ? item.name:'--'}</TableCell>
      <TableCell>{item.role && item.role.name?item.role.name:'--'}</TableCell>
      <TableCell>{item.contact_address ? item.contact_address:'--'}</TableCell>
      <TableCell>{item.concerned_person_name ? item.concerned_person_name:'--'}</TableCell>
      <TableCell>{item.start_date ? moment(item.start_date).format(displayDateFormatShort):'--'}</TableCell>
      <TableCell>{item.end_date ? moment(item.end_date).format(displayDateFormatShort):'--'}</TableCell>
      <TableCell>{item?.attachments?.length> 0 ?<AttachmentsRow attachmentsData={item?.attachments}/>:"--"}</TableCell> 
      <TableCell>
        <PartyCRU id={item.id} pltConstants={pltConstants} getResponseBack={getResponseBack} m="V" contractInfo={contractInfo}/>
        {checkPermission('contracts','party', 'D') ?<DeleteIcon fontSize='small' color='secondary' style={{cursor:'pointer'}} onClick={()=>deletePartylist(item.id)}/>: null}
      </TableCell>
    </TableRow>
  )
}
