import { globalDeleteService, globalGetService, globalPostService, globalPutService } from "../../../globalServices";
import checkApiStatus, { toastFlashMessage } from "../../../utils_v2";

export function getUtilsAttachmentApi(slug) {
    this.setState({ pageLoader: true })
    globalGetService(`/contracts/attachments/?contract_slug=${slug}`)
        .then(response => {
            this.setState({ pageLoader: false })
            if (response.data.statusCode == 200) {
                this.setState({
                    attachedDocList: response.data.list.data
                })
            } else {
                toastFlashMessage(response.data.message, 'error')
            }
        })
}


export function addUtilsAttachmentApi(data){
    return new Promise(
      function(resolve, reject){
        globalPostService(`contracts/attachments/`, data)
        .then(response => {
          resolve(response);
        })
      }
    )
  }
export function deleteUtilsAttachmentApi(data){
    return new Promise(
      function(resolve, reject){
        globalDeleteService(`contracts/attachments/${data.uuid}/`)
        .then(response => {
          resolve(response);
        })
      }
    )
  }
export function getRentalUtilsAttachmentApi(slug) {
    this.setState({ pageLoader: true })
    globalGetService(`/contracts/attachments/?contract_slug=${slug}`)
        .then(response => {
            this.setState({ pageLoader: false })
            if (response.data.statusCode == 200) {
                this.setState({
                    attachedRentalDocList: response.data.list.data
                })
            } else {
                toastFlashMessage(response.data.message, 'error')
            }
        })
}

export function addRentalUtilsAttachmentApi(data){
    return new Promise(
      function(resolve, reject){
        globalPostService(`contracts/attachments/`, data)
        .then(response => {
          resolve(response);
        })
      }
    )
  }

export function deleteRentalAttachmentApi(data) {
      return new Promise(
        function(resolve, reject){
          globalDeleteService(`contracts/attachments/${data.uuid}/`)
          .then(response => {
            resolve(response);
          })
        }
      )
    }
    export function getRegionListApi(data){
        this.setState({ pageLoader: true })
        globalGetService(`contracts/${data?.contract_slug}/regions/`)
            .then(response => {
                this.setState({ pageLoader: false })
                if (response.data.statusCode == 200) {
                    this.setState({
                        regionList: response.data.data
                    })
                } else {
                    toastFlashMessage(response.data.message, 'error')
                }
            })
    }
// export function deleteRentalAttachmentApi(data) {
//     this.setState({ pageLoader: true })
//     globalDeleteService(`contracts/attachments/${data.uuid}/`)
//         .then(response => {
//             this.setState({ pageLoader: false })
//             if (response.data.statusCode == 200) {
//                 toastFlashMessage(response.data.message, 'success')
//                 // this.getRentalUtilsAttachment(contractSlug)
//             }
//             else {
//                 toastFlashMessage(response.data.message, 'error')
//             }
//         })
// }