import { regexConstants, errorMsg1 } from '../../../constants/regEx';
export const addParty = {
  role: '',
  name: '',
  contact_address: '',
  jurisdiction: '',
  concerned_person_name: '',
  concerned_person_designation: '',
  start_date: null,
  end_date: null,
  remarks: ''
}
export const partyHd = [
  { label: 'Name', sortOption: false },
  { label: 'Role', sortOption: false },
  { label: 'Contact Address', sortOption: false },
  { label: 'Concerned Person', sortOption: false },
  { label: 'Start Date', sortOption: false },
  { label: 'End Date', sortOption: false },
  { label: 'Attachment(s)', sortOption: false },
  { label: 'Action', sortOption: false }
];
export const errorCode = {
  role:{
    0:'',
    1: 'Please select the Role'
  },
  roleObj: {
    required: true
  },
  name: {
    0:'',
    1: 'Please enter the Name',
  },
  nameObj: {
    required: true
  },
  start_date: {
    0:'',
    1: 'Please enter Start Date'
  },
  start_dateObj: {
    required: true
  },
  end_date: {
    0:'',
    1: 'Please enter End Date'
  },
  end_dateObj: {
    required: true
  }
}
