import React, { useEffect, useState } from 'react'
import { globalDeleteService, globalGetService, globalPostService } from '../../../utils_v2/globalApiServices'
import { DeletePopUp, EmptyCollection, FilterComponent, PageLoader, TableListComp } from '../../../shared_elements'
import { invoiceFleetFilterOps, summaryInviceHd, summaryInvoiceFilterOps } from '..'
import NumericLabel from 'react-pretty-numbers';
import { Grid, Paper, TableCell, TableRow, Button, Tooltip, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import moment from 'moment'
import { displayDateFormat, displayDateFormatShort, fieldDateFormat, invoiceStatusList } from '../../../constants'
import { checkApiStatus, checkPermission, dateTransform, getCurrencyFormat } from '../../../utils_v2'
import VisibilityIcon from '@material-ui/icons/Visibility';
import { browserHistory } from 'react-router';
import { STableLoader } from '../../../shared_elements/loaders';
import StatusChangeDialog from './StatusChangeDialog';
import { GetApp, InfoRounded } from '@material-ui/icons';
import { globalExportService, globalPutService } from '../../../globalServices';
import { downloadFileType } from '../../../utils';
import GenerateSummaryDialog from './GenerateSummaryDialog';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useSnackbar } from 'notistack';
import RestrictedAccess from '../../../shared_elements/components/RestrictedAccess';
const SummartInvoiceList = ({ item, index, setStatusChangeList, exportInvoice, getSummaryInvoiceList, toggleBulkOps, bulkIds }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [modal, setModal] = useState(false);
  const [circularLoader, setCircularloader] = useState(false)
  const [showMore, setShowMore] = useState(false);

  const unlinkInvoices = (item) => {
    setCircularloader(true);
    globalDeleteService(`/contracts/summary-invoice/${item.slug}/unlink/`)
      .then(response => {
        if (checkApiStatus(response)) {
          setCircularloader(false);
          enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          getSummaryInvoiceList()
          setModal(false)
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
  }
  const displayAssets = showMore ? item.assets :item.assets.slice(0,1);
  const toggleShowMore = () => setShowMore(!showMore);
  return (
    <>
      <TableRow hover tabIndex={-1} style={{ cursor: 'pointer' }}>
      <TableCell>  
      <Checkbox
          size="small"
          // disabled={item?.invoice_status?.value !== 1}
          checked={bulkIds.includes(item?.id)}
          onChange={(e) => {toggleBulkOps(item, e.target.checked) }}
          name="summary_invoice_ids"
          color="primary"
        />
      </TableCell>     
      <TableCell className="asset-info">
      <div className="summary-wrapper" style={{ flexWrap: 'nowrap' }}>
        <div className="asset" style={{marginLeft:'0px'}}>
          {displayAssets.map((asset, index) => (
            <div key={index}>
              {asset}{index < displayAssets.length - 1 ? ',' : ''}
            </div>
          ))}
        </div>
      </div>
      {item?.assets .length > 1 && (
        <div className="view-more-toggle" onClick={toggleShowMore}>
          {showMore ? 'View Less' : 'View More'}
        </div>
      )}
    </TableCell>
        <TableCell >{item.lessor && item.lessor ? item.lessor : '--'}</TableCell>
        <TableCell >
          {item.lessee && item?.lessee.logo ? <img style={{ width: "60px" }} src={item.lessee && item?.lessee} alt="Lessee Logo" /> : <span>{item.lessee && item.lessee ? item.lessee : '--'}</span>}
        </TableCell>
        <TableCell >{item.invoice_number}</TableCell>
        <TableCell>
          <p className={item.status?.value && invoiceStatusList[item.status?.value] + ' invoice-status'}>{item.status && item.status?.label ? item.status?.label.toUpperCase() : '--'}
            {(item?.status?.value == 1 || item?.status?.value == 2 || item.status?.value == 5) && checkPermission('contracts', 'summary_invoice', 'STS') && item.qb_invoice === null ?
              <i className="fa fa-caret-down" style={{ float: 'right' }}
                onClick={(e) => setStatusChangeList({ data: item, modal: true })}
              >
              </i> : null
            }
          </p>
          <span className="payment-text">
            {((item.status?.value == 2) && item.in_due) ? <span className="invoice-status-payment" >{"Overdue By " + dateTransform(item.due_date)}</span> : ''}
          </span>
          {
            item.status?.value == 5 && item.partial_paid_date ?
              <span className="payment-text">
                <span>Partial Payment: <NumericLabel params={getCurrencyFormat({ currency: item.currency, shortFormat: false })}>{item.partial_paid_amount ? item.partial_paid_amount : '--'}</NumericLabel><br /></span>
                <span>Payment Date: {moment(item.partial_paid_date).format(displayDateFormat)}</span>
                {moment(item.partial_paid_date).diff(moment(item.due_date), 'days') > 0 ? <span className="invoice-status-payment">{"Payment late by " + dateTransform(item.due_date)}</span> : ''}
              </span> : null
          }
          {
            item.status?.value == 3 && item.paid_date ?
              <span className="payment-text">
                Payment Date: {moment(item.paid_date).format(displayDateFormat)}
                {moment(item.paid_date).diff(moment(item.due_date), 'days') > 0 ? <span className="invoice-status-payment">{"Payment late by " + dateTransform(item.due_date, item.paid_date)}</span> : ''}
              </span> :
              null
          }
        </TableCell>
        <TableCell>{item.invoice_type && item.invoice_type.label ? item.invoice_type.label : '--'}</TableCell>
        <TableCell>{item.bank_name}</TableCell>
        <TableCell><NumericLabel params={getCurrencyFormat({ currency: item?.currency, shortFormat: false })}>{item.amount}</NumericLabel></TableCell>
        <TableCell style={{ width: "170px" }}>{item.start_date ? moment(item.start_date).format(displayDateFormat) : '--'} <br /><span style={{ marginLeft: '30px' }}>to</span><br /> {item.end_date ? moment(item.end_date).format(displayDateFormat) : '--'}</TableCell>
        <TableCell>{item.date ? moment(item.date).format(displayDateFormat) : '--'}</TableCell>
        <TableCell>{item.due_date ? moment(item.due_date).format(displayDateFormat) : '--'}</TableCell>
        <TableCell align='right'>
        {checkPermission('contracts','summary_invoice','R')?
          <Tooltip title='View'>
            <VisibilityIcon color='primary' fontSize='small' style={{ cursor: 'pointer' }} onClick={() => window.open(`/invoices/summary/${item?.slug}`,'_blank')} />
          </Tooltip>:null}
          {checkPermission('contracts','summary_invoice','EXP')?
          <Tooltip title='Export'>
            <GetApp color='primary' fontSize='small' style={{ cursor: 'pointer' }} onClick={() => exportInvoice(item)} />
          </Tooltip>:null}
          {item?.status?.value == 1 && checkPermission('contracts','summary_invoice','D')?
            <Tooltip title='Delete' placement='left-end' >
              <DeleteOutlineIcon color='secondary' fontSize='small' style={{ cursor: 'pointer' }} onClick={() => setModal(true)} />
            </Tooltip> : null}
        </TableCell>
      </TableRow>
      {<DeletePopUp
        modal={modal}
        toggleModalFn={() => { setModal(false) }}
        title="Delete Summmary Invoice"
        content={<h4>Are you sure you want to Delete?</h4>}
        deleteRecordFn={() => unlinkInvoices(item)}
        confirmText='Delete'
        circularloader={circularLoader}
      />}
    </>
  )
}

export default function SummaryInvoice() {
  const [summaryList, setSummaryList] = useState({})
  const [filter, setFilter] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [skeletonLoader, setSkeletonLoader] = useState(false)
  const [statusChange, setStatusChangeList] = useState(false)
  const [generateSummary, setGenerateSummary] = useState(false);
  const [bulkIds, setBulkIds] = useState([]);
  const [pageSelections, setPageSelections] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [syncDialogOpen, setSyncDialogOpen] = useState(false);
  const [lessor, setLessors] = useState({});
  const [lessee, setLessees] = useState({});
  const [bank, setBank] = useState([]);
  const [owners, setOwners] = useState([])
  const [sort_by, setSortBy] = useState("");
  const [sort, setSort] = useState("");

  useEffect(() => {
    getSummaryInvoiceList({}, 'skeletonLoader');
    getLessees();
    getLessors();
    getBankDetail();
    getOwners();
  }, [])
  const getSummaryInvoiceList = (query, loaderType) => {
    if (loaderType == 'skeletonLoader') {
      setSkeletonLoader(true)
    } else {
      setLoading(true)
    }
    globalGetService(`contracts/summary-invoice/list/`, query)
      .then(response => {
        setSkeletonLoader(false)
        setLoading(false)
        setSummaryList(response.data?.data)
        if(query){
        delete query?.sort;
        delete query?.sort_by;
        delete query?.page;
        delete query?.per_page;
        setFilter(query)
        }
      })
  }
  const exportInvoice = (item) => {
    setLoading(true)
    globalExportService(`contracts/summary-invoice/${item.slug}/?download=pdf`)
      .then(response => {
        setLoading(false)
        downloadFileType(response, `Summary_invoice_${item?.invoice_number}.`, 'pdf')
      })
  }

  const toggleBulkOps = (item, checked, currentPage) => {
    setPageSelections(prevSelections => {
      const updatedSelections = { ...prevSelections };
      if (checked) {
        updatedSelections[currentPage] = updatedSelections[currentPage] || [];
        updatedSelections[currentPage].push(item.id);
      } else {
        updatedSelections[currentPage] = updatedSelections[currentPage].filter(id => id !== item.id);
      }
      return updatedSelections;
    });
  };
  
  const exportInvoices = () => {
    setLoading(true)
    const summaryInvoiceIds = Object.values(pageSelections).flat()
    globalExportService(`contracts/summary-invoice/bulk-export/?summary_invoice_ids=${summaryInvoiceIds}`)
        .then(response => {
            setLoading(false)
            downloadFileType(response, `Summary_Invoices_${moment().format(fieldDateFormat)}`, '.zip')
            setPageSelections([])
        })
  }
  const handleSelectAll = (event, currentPage) => {
    if (event.target.checked) {
      const allIds = summaryList.list.map(item => item.id);
      setPageSelections(prevSelections => ({ ...prevSelections,[currentPage]: allIds }));
    } else {
      setPageSelections(prevSelections => ({ ...prevSelections,[currentPage]: [] }));
    }
  };
  
  const isSelectAllChecked = summaryList?.list?.length > 0 && pageSelections[currentPage]?.length === summaryList.list.length;
  const summaryInvoiceIds = Object.values(pageSelections).flat()
  const clearSelection = () => {
    setPageSelections({}); 
  }
  const getThirdPartyApp = (sync) => {
    localStorage.setItem('thirdPartyState', sync);
    setLoading(true)
    globalGetService(`contracts/app-plugin/quickbooks/authenticate/`)
      .then(response => {
        if (checkApiStatus(response)) {
          window.open(response.data?.data?.url, '_blank');
          setLoading(false)
        }
      })
      .catch(() => {
        enqueueSnackbar('Error syncing with QuickBooks', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
      });
  };
  const getThirdParty = (data) => {
    getThirdPartyApp(data)
  };
  const getLessors = () => {
    globalGetService(`console/owner/?dropdown=true&lessor_use=true`)
      .then(response => {
        if (checkApiStatus(response)) {
          setLessors(response?.data?.data)
        }
      })
  }
  const getOwners = () => {
    globalGetService(`console/owner/`)
      .then(response => {
        if (checkApiStatus(response)) {
          setOwners(response?.data?.data)
        }
      })
  }
  const getLessees = () => {
    globalGetService(`console/lessees/`,)
      .then(response => {
        if (checkApiStatus(response)) {
          setLessees(response?.data?.data?.lessees)
        }
      })
  }
  const getBankDetail = () => {
    globalGetService(`console/bank-details/?dropdown=true`)
      .then(response => {
        if (checkApiStatus(response)) {
          setBank(response.data?.data)
        }
      })
  }
const createSortHandler = (sortField) => {
  let sort_order = 'asc';
  if (sortField === sort) {
      sort_order = sort_by === 'asc' ? 'desc' : 'asc'
  }
  getSummaryInvoiceList({...filter, page: 1, per_page: summaryList?.pagination.per_page, sort_by: sort_order, sort: sortField }, 'pageLoader')
  setSort(sortField);
  setSortBy(sortField === sort ? sort_by === 'asc' ? 'desc' : 'asc' : 'asc')
}
  
  let filterOptions = Object.assign({}, summaryInvoiceFilterOps);
  filterOptions = {
    ...filterOptions,
    lessee_id: {
      ...filterOptions?.lessee_id,
      options: lessee
    },
    lessor_id: {
      ...filterOptions?.lessor_id,
      options: lessor
    },
  }
return (
    <div>
      {isLoading ? <PageLoader /> : null}
      {skeletonLoader ? <STableLoader count={8} /> :
        checkPermission('contracts','summary_invoice','R')?
        <>
            <Paper square style={{ padding: '6px 10px' }}>
              <Grid container spacing={1} alignItems='center' style={{ justifyContent: "space-between", padding: '5px 0px' }}>
                <Grid item style={{ justifyContent: "flex-start", display: 'flex', alignItems: 'center' }}>
                <FilterComponent
                filter={filter}
                filterMenu={filterOptions} 
                getResponseBack={(applyFilter) => {getSummaryInvoiceList({ ...applyFilter}, 'pageLoader')}}
              />
                  {summaryInvoiceIds.length ?
                  <Button size='small' variant='outlined' color='secondary' style={{marginLeft:'3px'}} onClick={clearSelection}>
                    Unselect All
                  </Button>:null}
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Grid style={{marginRight:'3px'}}>
                 <Button size='small' variant="outlined" color='primary' onClick={() =>  {setSyncDialogOpen(true)}}>Sync from qb</Button>
                 </Grid>
                  <Grid style={{ marginRight: '3px' }}>
                    <Button disabled={!summaryInvoiceIds.length} size='small' variant='outlined' color='primary' onClick={() => exportInvoices()}>
                      Export ({summaryInvoiceIds.length})
                    </Button>
                  </Grid>
                  <Grid>
                    {checkPermission('contracts', 'summary_invoice', 'C') ?
                      <Button size='small' variant='contained' color='primary' onClick={() => setGenerateSummary({ modal: true })}>
                        Generate Summary Invoice
                      </Button>
                      : null}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <TableListComp
              heads={[{label: (<div style={{ display: 'flex', alignItems: 'center' }}><Tooltip placement="bottom-start" title="By checking this, you will select all invoices from this page"><Checkbox size="small" checked={isSelectAllChecked}  onChange={(e) => handleSelectAll(e, currentPage)} /></Tooltip><span style={{ fontSize: '8px', marginLeft: '5px' }}>Select All</span></div>),},...summaryInviceHd]}
              bulkOperation={false}
              sort_by={sort_by}
              sort={sort}
              maxheight={(window.innerHeight - 225) + 'px'}
              data={summaryList?.list?.map((item, index) =>
                <SummartInvoiceList
                  setStatusChangeList={setStatusChangeList}
                  index={index}
                  exportInvoice={exportInvoice}
                  item={item}
                  key={item.id}
                  toggleBulkOps={(item, checked) => toggleBulkOps(item, checked, currentPage)}
                  bulkIds={pageSelections[currentPage] || []}
                  getSummaryInvoiceList={getSummaryInvoiceList}
                />
              )
              }
              createSortHandler={createSortHandler}
              onChangePage={(event, newPage) => { getSummaryInvoiceList({ ...filter, page: newPage + 1, per_page: summaryList.pagination.per_page, sort_by: sort_by, sort: sort }, 'pageLoader');setCurrentPage(newPage + 1)}}              
              onChangeRowsPerPage={(event) => getSummaryInvoiceList({ ...filter, page: 1, per_page: event.target.value, sort_by: sort_by, sort: sort }, 'pageLoader')}
              pagination={summaryList.pagination}
              noRecord={summaryList?.list?.length || isLoading ? null :
                <EmptyCollection
                  title="No records found"
                />
              }
            />
        </>:<RestrictedAccess/>
      }
      {
        statusChange?.modal ?
          <StatusChangeDialog
            setStatusChangeList={setStatusChangeList}
            invStatus={statusChange?.data?.status}
            invData={statusChange?.data}
            getResponseBack={getSummaryInvoiceList}
          />
          : null
      }

      <GenerateSummaryDialog
        setGenerateSummary={setGenerateSummary}
        generateSummary={generateSummary}
        getResponseBack={getSummaryInvoiceList}
        filter={setFilter}
      />
      <Dialog
        open={syncDialogOpen}
        maxWidth={'md'}
      >
        <DialogTitle >Sync with QuickBooks</DialogTitle>
        <DialogContent dividers>
          <p>Are you sure you want to Sync Invoice's from QuickBooks to SPARTA ?</p>
          <span>This syncing process will pull the data ('Status') to SPARTA</span>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSyncDialogOpen(false)} variant='outlined' size='small' color="primary">No</Button>
          <Button onClick={() =>{ getThirdParty('SyncFromQB');setSyncDialogOpen(false)}} variant='contained' size='small' color="primary">Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
