import React from 'react'
import {  Dialog, DialogTitle, DialogContent, Button, DialogActions,  List, ListItem, ListItemText } from '@material-ui/core';

const AttachmentListModal = ({attachmentsData,OnItemClick,toggleModalFn}) => {
  return (
    <Dialog open={true} className='mou-dialog'>
    <DialogTitle>Attachment(s) [Count: {attachmentsData?.length}]</DialogTitle>
    <DialogContent dividers style={{minWidth:"500px"}}>
      <List style={{ border: "1px solid #ede8e8", padding:"0px"}}>
        {attachmentsData?.map((file, index) => (
          <ListItem button key={index} style={{    backgroundColor: index % 2 === 0 ? '#ebeff6' : '#fff' }} onClick={() => OnItemClick(file)}>
            <ListItemText primary={file.name} />
          </ListItem>
        ))}
      </List>
    </DialogContent>
    <DialogActions>
      <Button variant='outlined' color='primary' size='small' onClick={() => toggleModalFn(false)}>Close</Button>
    </DialogActions>
  </Dialog>
  )
}

export default AttachmentListModal