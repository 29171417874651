import React from 'react';
import {Grid, IconButton } from '@material-ui/core';
import NumericLabel from 'react-pretty-numbers';
import { getCurrencyFormat } from '../../../../utils_v2';
import SecurityDepositCRU from './SecurityDepositCRU';
import { AttachmentsRow } from '../../components';
export default function SecurityDepositHd({item, previewDetail, pltConstants, getResponseBack, contractInfo}){
  return(
    <div className="security-deposit-hd">
      <Grid container spacing={1}>
        <Grid item md={2} xs={12}>
          <h4>Security Deposit Type</h4>
          <h6>{item.security_deposit_type.label}</h6>
        </Grid>
        <Grid item md={2} xs={6}>
          <h6>
            <NumericLabel params={getCurrencyFormat()}>{ item.security_deposit_transactions.reduce((total, next) => total + next.amount, 0 )}</NumericLabel>
            <br/>/<NumericLabel params={getCurrencyFormat()}>{item.amount}</NumericLabel>
          </h6>
        </Grid>
        <Grid item md={1} xs={6}>
          <p>Security Against</p>
          <h6>{item.security_against}</h6>
        </Grid>
        <Grid item md={2} xs={6}>
          <p>Security Deposit Refundable?</p>
          <h6>{item.is_refundable ? 'YES': 'NO'}</h6>
        </Grid>
        <Grid item md={2} xs={6}>
          <p>Interest Refundable?</p>
          <h6>{item.is_interest_refundable ? 'YES':'NO'}</h6>
        </Grid>
        <Grid item md={2} xs={6}>
          <p>Attachment(s)</p>
          <h6>{item?.attachments?.length> 0 ?<AttachmentsRow attachmentsData={item?.attachments}/>:"--"}</h6>
        </Grid>
        <Grid item md={1} xs={12}>
          <p>Action</p>
          <SecurityDepositCRU id={item.id} m="V" pltConstants={pltConstants} getResponseBack={getResponseBack} contractInfo={contractInfo} />
        </Grid>
      </Grid>
    </div>
  )
}
