import React, {Component} from 'react';
import { Row, Col, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import { imgStoragePath } from '../../constants'
import { getLocalStorageInfo } from '../../utils';
import exportIcon from '../../shared/assets/img/export_icon.svg';
import Select from 'react-select';
import { halcyonInstance } from '../../shared_elements/components';
import { jetStreamInstance } from '../../lease_management/Leases';
export default class AssetsTabs extends Component{
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
          dropdownOpen: false
        };
      }
      toggle() {
        this.setState({
          dropdownOpen: !this.state.dropdownOpen
        });
      }
    render(){
      const { assetCounts } = this.props
      var totalAssets = 0
      if(assetCounts){
        Object.keys(assetCounts).map(item => {
          totalAssets = totalAssets + assetCounts[item]
          return item
        })
      }
        const userInfo = getLocalStorageInfo().user.permission
        var showTabs = true
        // Object.keys(userInfo).map(key => {
        //   showTabs = userInfo[key].is_saas ? false : showTabs
        //   return key
        // })
        let lessorIds = []
    		 if(window.location.href.includes('qa')){
    			 lessorIds = [241, 86]
    		 }else if(window.location.href.includes('dev')){
    			 lessorIds = [289, 89]
    		 }else{
    			 lessorIds = [86]
    		 }
        // Object.keys(userInfo).map(key => {
        //   showTabs = userInfo[key].is_saas ? false : showTabs
        //   return key
        // })
        if(lessorIds.includes(getLocalStorageInfo().defaultLessor.id)){
          showTabs = true
        }
        const hideModuleForJac =  getLocalStorageInfo()?.defaultLessor?.id === 442 
        return(
            <Row>
                <Col md="12" >
                    <ul className="list-inline tab-left">
                    { halcyonInstance ? null :
                        <li onClick = {() => this.props.updateAssetTab('all')} className={this.props.activeTab == 'all' ? "list-inline-item active" : "list-inline-item"}>
                            All ({totalAssets})
                        </li> }
                        { halcyonInstance ? null :
                        <li onClick = {() => this.props.updateAssetTab('1')} className={this.props.activeTab == '1' ? "list-inline-item active" : "list-inline-item"}>
                            Aircraft ({assetCounts && assetCounts.aircraft ? assetCounts.aircraft : 0})
                        </li>
                        }
                        <li onClick = {() => this.props.updateAssetTab('2')} className={this.props.activeTab == '2' ? "list-inline-item active" : "list-inline-item"}>
                            Engine ({assetCounts && assetCounts.engine ? assetCounts.engine : 0})
                        </li>
                        {showTabs  && !halcyonInstance?
                          <li onClick = {() => this.props.updateAssetTab('3')} className={this.props.activeTab == '3' ? "list-inline-item active" : "list-inline-item"}>
                              APU ({assetCounts && assetCounts.apu ? assetCounts.apu : 0})
                          </li>
                          :null
                        }
                        { showTabs  && !halcyonInstance ?
                          <li onClick = {() => this.props.updateAssetTab('4')} className={this.props.activeTab == '4' ? "list-inline-item active" : "list-inline-item"}>
                              LG ({assetCounts && assetCounts.lg ? assetCounts.lg : 0})
                          </li>
                          :null
                        }
                        {
                          showTabs && !halcyonInstance ?
                          <li onClick = {() => this.props.updateAssetTab('5')} className={this.props.activeTab == '5' ? "list-inline-item active" : "list-inline-item"}>
                              Propeller ({assetCounts && assetCounts.propeller ? assetCounts.propeller : 0})
                          </li>
                          :null
                        }

                         { hideModuleForJac || halcyonInstance ? null : <li onClick = {() => this.props.updateAssetTab('6')} className={this.props.activeTab == '6' ? "list-inline-item active" : "list-inline-item"}>
                              Fan ({assetCounts && assetCounts.fans ? assetCounts.fans : 0})
                          </li> }

                          { hideModuleForJac || halcyonInstance ? null : <li onClick = {() => this.props.updateAssetTab('7')} className={this.props.activeTab == '7' ? "list-inline-item active" : "list-inline-item"}>
                              HPC ({assetCounts && assetCounts.hpc ? assetCounts.hpc : 0})
                          </li> }

                          { hideModuleForJac || halcyonInstance ? null : <li onClick = {() => this.props.updateAssetTab('8')} className={this.props.activeTab == '8' ? "list-inline-item active" : "list-inline-item"}>
                              HPT ({assetCounts && assetCounts.hpt ? assetCounts.hpt : 0})
                          </li> }


                          { hideModuleForJac || halcyonInstance ? null : <li onClick = {() => this.props.updateAssetTab('9')} className={this.props.activeTab == '9' ? "list-inline-item active" : "list-inline-item"}>
                              LPT ({assetCounts && assetCounts.lpt ? assetCounts.lpt : 0})
                          </li> }
                          { jetStreamInstance ?<li onClick = {() => this.props.updateAssetTab('10')} className={this.props.activeTab == '10' ? "list-inline-item active" : "list-inline-item"}>
                              Component ({assetCounts && assetCounts.component ? assetCounts.component : 0})
                          </li>:null }
                    </ul>
                    <hr/>
                    <ul className="list-inline tab-right flex-centered">
                        <li className="list-inline-item download-block">
                            <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                <DropdownToggle caret size="sm">
                                   <span><img width="12" src={exportIcon}  alt="export"/> Export </span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem  onClick={() => this.props.downLoadAsset('pdf',  this.props.assetFilter)}><img src={imgStoragePath+"pdf_icon.png"} alt="img"/>PDF</DropdownItem>
                                    <DropdownItem  onClick={() => this.props.downLoadAsset('xls', this.props.assetFilter)} ><img src={imgStoragePath+"excel_icon.png"} alt="img"/>Excel</DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </li>
                        <li className="list-inline-item count-block" style={{display: 'none'}}>
                            <span> {this.props.total} items </span>
                          </li>
                          <li style={{display: 'inline', fontSize: '13px',borderLeft: '1px solid #4051b5', paddingLeft: '10px'}}> Per Page </li>
                        <li className="list-inline-item" style={{ paddingLeft: '10px'}}>
                            <Select
                              value={this.props.pagintionInfo.per_page}
                              isClearable={true}
                              style={{width: '70px', display: 'inline-block'}}
                              onChange={(option) => this.props.updateAssetRows(option?.value)}
                              options={[{id: 10, value: 10},{id: 50, value: 50}, {id: 100, value: 100}, {id: 200, value: 200}]}
                              className="custom-select-block"
                              placeholder="Number of Rows"
                              valueKey="id"
                              labelKey="value"
                              multi={false}
                           />
                        </li>
                    </ul>
                </Col>
            </Row>

        );
    }

}
