import { globalGetService, globalExportService, globalPostService, globalPutService } from '../../utils_v2/globalApiServices';
import { checkApiStatus, downloadFileType } from '../../utils_v2';
import { trackActivity } from '../../utils_v2/mixpanel';
export function getContractListApi(props={}, query={}, loaderType){
  this.setState({[loaderType]: true})
  globalGetService(`${props.params.type}/${props.params.aircraft_slug}/contracts/`, query)
  .then(response => {
    this.setState({[loaderType]: false})
    if(checkApiStatus(response)){
      this.setState({
        list: response.data.data,
        archived_asset: response.data.archived_asset,
        records_uuid: response.data.lease_dataroom_uuid ? response.data.lease_dataroom_uuid : '',
        disabled_contracts:response.data.disabled_contracts
      })
    }
  })
}
export function getFleetContractsApi(props, query, loaderType, dataType){
  this.setState({[loaderType]:true});
  let filterQuery = Object.assign({}, query);
  globalGetService(`contracts/v2/list-lease/`, filterQuery)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      delete query.page;
      delete query.per_page;
      delete query.sort;
      delete query.sort_by;
      this.setState({
        leases:response.data.data,
        filter:query
      });
      if(dataType){
        this.setState({open:true});
      }
    }
  })
}
export function getActiveContractApi(props={}){
  return new Promise(
    function(resolve, reject){
      globalGetService(`console/contract/${props.params.type}/${props.params.aircraft_slug}/`)
      .then(response => {
        resolve(response);
      })
    }
  )
}
export function getContractDetailApi(props={}){
  return new Promise(
    function(resolve, reject){
      globalGetService(`${props.params.type}/${props.params.aircraft_slug}/contracts/${props.params.contract_slug}/`)
      .then(response => {
        resolve(response);
      })
    }
  )
}
export function getContractApi(props={}, query={}){
  globalGetService(`${props.params.type}/${props.params.aircraft_slug}/contracts/${props.params.contract_slug}/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        contract: response.data.data,
        contractEdit: response.data.data
      })
    }
    this.setState({skeletonLoader:false});
  })
}

export function addEditContractApi(props={}, data){
  return new Promise(
    function(resolve, reject){
      if(data.id){
        globalPutService(`${props.params.type}/${props.params.aircraft_slug}/contracts/${props.params.contract_slug}/`, data)
        .then(response => {
          resolve(response);
        })
      }else{
        globalPostService(`${props.params.type}/${props.params.aircraft_slug}/contracts/`, data)
        .then(response => {
          resolve(response);
        })
      }
    }
  )
}

export function editContractApi(props={}, data={}){

}
export function publishContractApi(props={}, query={}){
  globalGetService(`contracts/${props.params.contract_slug}/publish/`, query)
  .then(response => {
    if(checkApiStatus(response)){

    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}

export function usageContractInfoApi(params={}, query={}){
  globalPostService(`console//${params.type}/${params.aircraft_slug}/license/contract/usage/`, query)
  .then(response => {
    if(checkApiStatus(response)){

    }
  })
}

export function changeStatusApi(contract, query={}, props){
  query = {...query, status:query.value.status}
  if(query.lease_end_date== ''|| query.lease_end_date== null){
    query = {...query, lease_end_date:contract.contractual_end_date}
  }
  delete query['value'];
  this.setState({formSubmitLoader: true})
  globalPutService(`contracts/${contract.slug}/lease-status-change/`, query)
  .then(response => {
    this.setState({formSubmitLoader:false});
    if(checkApiStatus(response)){
      this.getContractListApi(props, {}, 'formSubmitLoader');
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}

export function exportFleetLeasesApi(props, query){
  this.setState({pageLoader:true});
  let filterQuery = Object.assign({}, query);
  delete filterQuery.lease_duration
  if(query.lease_duration && query.lease_duration.start_date){
    filterQuery = {
      ...filterQuery,
      start_date:query.lease_duration.start_date
    }
  }
  if(query.lease_duration && query.lease_duration.end_date){
    filterQuery = {
      ...filterQuery,
      end_date:query.lease_duration.end_date
    }
  }
  globalExportService(`contracts/v2/list-lease/`, filterQuery)
  .then(response => {
    this.setState({pageLoader:false});
    // downloadFileType(response.data, 'Lease_List', query.download);
    trackActivity('Lease Management', {
      page_title: 'Fleet Leases List',
      event_type: 'File Export',
      activity_type:'Report sent to email',
      file_type: query?.download
    })
    this.props.enqueueSnackbar("Request Queued!! You'll receive the requested file on your registered email shortly.", {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}

export function getLeaseTypeGraphApi(props={}, genericFilter, query, loaderType){
  this.setState({[loaderType]:true});
  let filterQuery = {...genericFilter, ...query};
  globalGetService(`contracts/v2/dashboard/lease-type/`,filterQuery)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      this.setState({
        leaseTypeGraph: response.data.data,
        filterLeaseType:query
      })
    }
  })
}

export function getLeaseStatusGraphApi(props={}, genericFilter, query, loaderType, filterType){
  this.setState({[loaderType]:true});
  let filterQuery = {...genericFilter, ...query};
  globalGetService(`contracts/v2/dashboard/lease-summary/`, filterQuery)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      this.setState({
        leaseStatGraph: response.data.data,
        filterLeaseStatus:query
      })
    }
  })
}

export function getOwnerGraphApi(props={}, genericFilter, query, loaderType,filterType){
  this.setState({[loaderType]:true});
  let filterQuery = {...genericFilter, ...query};
  globalGetService(`contracts/v2/dashboard/lease-owner/`,filterQuery)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      this.setState({
        ownerGraphData: response.data.data,
        filterOwnerGraph:query
      })
    }
  })
}

export function getLesseeGraphApi(props={}, genericFilter, query, loaderType,filterType){
  this.setState({[loaderType]:true});
  let filterQuery = {...genericFilter, ...query};
  globalGetService(`contracts/v2/dashboard/lease-lessee/`, filterQuery)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      this.setState({
        lesseeGraphData: response.data.data,
        filterLesseeGraph:query
      })
    }
  })
}
export function getLeasesAssetTypeFilterOptionsApi(props={}, query, loaderType, filterType){
  // Need to remove
}

export function getLeasesSerialNoFilterOptionsApi(props={}, query, loaderType, filterType){
  // Need to remove
}

export function getLeasesLesseeFilterOptionsApi(filterType){
  globalGetService(`contracts/v2/dashboard/lease-generic-filters/${filterType}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        lesseeOption: response.data.data
      })
    }
  })
}

export function getLeasesLessorFilterOptionsApi(filterType){
  globalGetService(`contracts/v2/dashboard/lease-generic-filters/${filterType}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        lessorOptions: response.data.data
      })
    }
  })
}

export function getLeasesOwnerFilterOptionsApi(filterType){
  globalGetService(`contracts/v2/dashboard/lease-generic-filters/${filterType}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        ownerOptions: response.data.data
      })
    }
  })
}

export function getAssetIdentifierApi(assetIdentifier, stateType){
  globalGetService(`contracts/v2/dashboard/lease-generic-filters/${assetIdentifier}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({[stateType]:response.data.data})
    }
  })
}
export function getLesseeFilterApi(query){
  globalGetService(`console/lessees/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({lesseeFilter:response.data.data?.lessees})
    }
  })
}

