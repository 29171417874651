import React,{useState} from 'react';
import { TableRow, TableCell, TextField} from '@material-ui/core';
export default function CapeTownList({cape_town_clauses, flag, onFieldChange}){
  const maxLength = 800;
  const [showFullText, setShowFullText] = useState(false);
  const displayedText =cape_town_clauses?.length ? showFullText  ? cape_town_clauses : cape_town_clauses.substring(0, maxLength) : null;
  const isTextLong = cape_town_clauses?.length > maxLength;
  const handleShowMore = () => {setShowFullText(!showFullText);};
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>
        { flag ?
          <TextField
            id="cape_town_clauses"
            fullWidth
            margin="normal"
            value={cape_town_clauses ? cape_town_clauses:''}
            InputLabelProps={{shrink: true}}
            multiline
            rows="6"
            onChange={(e) => onFieldChange(e, 'cape_town_clauses', e.target.value)}
          />:
          <>  <div>
            {cape_town_clauses ? <> <p 
            dangerouslySetInnerHTML={{
              __html: displayedText + (isTextLong && !showFullText ? '...' : ''),
            }}
          />
          {isTextLong && (
            <p style={{ cursor: 'pointer', color: 'blue', fontSize:'11px' }} onClick={handleShowMore}>
              {showFullText ? 'Show Less' : 'Show More'}
            </p>
          )}</> : "--"}
        </div>
        </>
        }
      </TableCell>
    </TableRow>
  )
}
