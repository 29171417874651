import React, { useState } from 'react'
import AttachmentListModal from './AttachmentListModal';
import { CustomChip, PreviewDocsModal } from '../../../shared_elements';

const AttachmentsRow = ({ attachmentsData }) => {
    const [previewDocs, setPreviewDocs] = useState("")
    const [openAttachment, setOpenAttachment] = useState(false)
    const attachmentsToShow = Array.isArray(attachmentsData) ? attachmentsData.slice(0, 2) : [];
    return (<>
        {attachmentsToShow?.length ? attachmentsToShow?.map((file, index) => (
            <CustomChip
                style={{ marginRight: '4px', marginTop: attachmentsData?.length > 2 ? '7px' : '', width: '50px', height: '15px', fontSize: '10px' }}
                title={file.name}
                index={index}
                label={file.name.substring(0, 14) + '...'}
                onClick={(e) => { e.preventDefault(); setPreviewDocs(file); }}
            />
        )) : '--'}
        {attachmentsData?.length > 2 && (
            <div style={{ marginTop: '10px' }}>
                <CustomChip
                    variant="default"
                    title = "Show More"
                    style={{ cursor: 'pointer', height: '15px', fontSize: '9px' }}
                    label={openAttachment ? 'Show Less' : 'Show More'}
                    onClick={() => setOpenAttachment(!openAttachment)}
                />
            </div>
        )}
        {previewDocs ? (
            <PreviewDocsModal previewDocs={previewDocs} toggleModalFn={() => setPreviewDocs("")} />
        ) : null}
        {openAttachment ?
            <AttachmentListModal attachmentsData={attachmentsData} OnItemClick={setPreviewDocs} toggleModalFn={() => setOpenAttachment(false)} /> : null}
    </>)
}

export default AttachmentsRow