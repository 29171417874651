import React, { useState } from 'react';
import { TableRow, TableCell, Tooltip, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import { getLocalStorageInfo } from '../../../../utils';
import { checkPermission } from '../../../../utils_v2';
import { displayDateFormatShort } from '../../../../constants';
import LeaseOptionCRU from './LeaseOptionCRU';
import { AttachmentsRow } from '../../components';
export default function LeaseOptionList({item, previewDetail, getResponseBack, pltConstants, contractInfo,deleteEolList}){
  const [showMore, setShowMore] = useState({});
  const toggleDescription = (id) => {
    setShowMore({
      ...showMore,
      [id]: !showMore[id],
    });
  };
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{item.lease_option.label}</TableCell>
      <TableCell>
      {item?.details && item?.details?.length > 200 ? (
          <div style={{ maxWidth: '400px' }}>
            {showMore[item?.id] && showMore[item?.id] ? (
              <span>
                <span dangerouslySetInnerHTML={{ __html: item?.details.replace(/\n/g, '<br />') }} />
                <a href='#' style={{ color: '#0E7FE1', fontSize: '14px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }} >
                  less
                </a>
              </span>
            ) : (
              <span>
                <span dangerouslySetInnerHTML={{ __html: item?.details.substring(0, 190).replace(/\n/g, '<br />') }} />
                <a href='#' style={{ color: '#0E7FE1', fontSize: '14px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }} >
                  ..more
                </a>
              </span>
            )}
          </div>
        ) : (
          <div>
                <span dangerouslySetInnerHTML={{ __html: item?.details.replace(/\n/g, '<br />') }} />
          </div>
        )}
      </TableCell>
      <TableCell>
        {item.validity_start_date ? moment(item.validity_start_date).format(displayDateFormatShort): ''} - {item.validity_end_date ? moment(item.validity_end_date).format(displayDateFormatShort):''}
      </TableCell>
      <TableCell>
        {item.notice_period_start_date ? moment(item.notice_period_start_date).format(displayDateFormatShort): ''} - {item.notice_period_end_date ? moment(item.notice_period_end_date).format(displayDateFormatShort):''}
      </TableCell>
          <TableCell>{item?.attachments?.length> 0 ?<AttachmentsRow attachmentsData={item?.attachments}/>:"--"}</TableCell> 
      <TableCell>
        <LeaseOptionCRU id={item.id} m="V" pltConstants={pltConstants} getResponseBack={getResponseBack}  contractInfo={contractInfo} />
        {checkPermission('contracts','eol_options', 'D') ?<DeleteIcon fontSize='small' color='secondary' onClick={()=>deleteEolList(item.id)}/>: null}
      </TableCell>
    </TableRow>
  )
}
