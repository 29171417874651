import React, { Component } from 'react';
import { withRouter, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { TableListComp, DeletePopUp, EmptyCollection, PageLoader } from '../../../../shared_elements';
import { Header, EditSidebar, ContractHeadertext, CtrTableHd, ContractSideBar, FormModeType } from '../../../../applications/contracts/Elements';
import { SubleaseList, SubleaseCRU } from '../components';
import { ContractWrapper, LeaseDetialTab } from '../../../Elements';
import { STableLoader } from '../../../../shared_elements/loaders';
import { getSubleaseListApi,deleteSubLeaseApi } from '../apiServices';
import { subleaseHd } from '../';
import { sExportContractsAc } from '../../../actionCreators';
import { assetsType } from '../../../../constants';
import { trackActivity } from '../../../../utils_v2/mixpanel';
import { getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { contrHdlineInfoAc } from '../../../../applications/contracts/HeadlinesInfo/actionCreators';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess'
import { titanInstance } from '../../../../shared_elements/components';
class SubLeases extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      list:[],
      deleteItem:{modal:false, data:null}
    }
    this.getSubleaseListApi = getSubleaseListApi.bind(this);
    this.deleteSubLeaseApi = deleteSubLeaseApi.bind(this);
  }
  componentDidMount(){
    this.getSubleaseListApi(this.props);
    this.props.fetchContractInfo();
    trackActivity('Lease Management', {
      page_title: 'SubLeases',
      event_type: 'Page Visited'
    })
  }
  
  getResponseBack = () => {
    this.getSubleaseListApi(this.props);
  }
  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  render(){
    const { list, skeletonLoader, pageLoader, deleteItem } = this.state;
    const { params, contractSummaryLoader, contractInfo } = this.props;
    return(
      <div className="contract-container">
        <Header type="brief" headingText="Lease Management" params= {this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract} />
        <ContractSideBar application="SubLeases" type="brief" params={this.props.params} />
        <div className="contract-content-blk">
          <LeaseDetialTab url='sub-lease' primaryTab='basic-betails' secondaryTab='subLease' />
          <ContractWrapper
            url='audit/contracts/ctsublease/'
            queryParams={{action:0, contract:this.props.params.contract_slug}}
            revision={true}
            params={params}
            buttonText={ checkPermission('contracts','sublease', 'C') ?<SubleaseCRU getResponseBack={this.getResponseBack} contractInfo={contractInfo}/> : null}
          >
            { skeletonLoader ?
              (<STableLoader count={5} />):
              (
                <TableListComp
                  heads={subleaseHd}
                  data={list.map((item,index) =>
                    <SubleaseList
                      getResponseBack={this.getResponseBack}
                      key={index}
                      item={item} 
                      contractInfo={contractInfo}
                      deleteSubLease={() => this.setState({deleteItem:{modal:true, data:item}})}
                      />
                    )
                  }
                  noRecord={ list.length ? null:
                    <EmptyCollection
                      title="No records found"
                    />
                  }
                />
              )
            }
            { pageLoader || contractSummaryLoader ? <PageLoader />:null}
          </ContractWrapper>
        </div>
        { deleteItem.modal?
          <DeletePopUp
            modal={deleteItem.modal}
            toggleModalFn={() => this.setState({deleteItem:{modal:false, data:null}})}
            title="Delete"
            content={<h4>Are you sure you want to delete?</h4>}
            deleteRecordFn={() => this.deleteSubLeaseApi(this.props,deleteItem)}
          />:null
        }
      </div>
    )
  }
}
const mapStateToProps = state => ({
  contractSummaryLoader: state.contractReducer.contractSummaryLoader,
  contractInfo: state.ContrHdlineReducer.contrHdlineInfo,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
    fetchContractInfo: () => dispatch(contrHdlineInfoAc(ownProps.params)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(SubLeases,['contracts','sublease', 'R'])));
