import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle,  } from '@material-ui/core';
import { downloadFile } from '../../utils_v2';

const PreviewDocsModal = ({previewDocs,toggleModalFn}) => {
  return (
    <Dialog open={true} fullScreen >
    <DialogTitle>{previewDocs?.name}</DialogTitle>
    <DialogContent style={{ height: '' }} >
      {previewDocs?.extension === 'csv' ? (
        <object id="myObject" data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/xls_viewer/index.html?file=${encodeURIComponent(previewDocs?.url)}`} style={{ width: '100%', height: window.innerHeight - 100 }} />
      ) : ['xls', 'xlsx', 'docx'].includes(previewDocs?.extension?.toLowerCase()) ? (
        <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(previewDocs?.url)}`} width='100%' height={window.innerHeight} frameborder='0' />
      ) : ['jpg', 'jpeg', 'png'].includes(previewDocs?.extension?.toLowerCase()) ? (
        <img
          src={previewDocs?.url}
          alt="Attachment"
          style={{ width: '100%', height: 'auto' }}
        />) : previewDocs?.extension === 'pdf' ? (
          <object type="text/html" data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${encodeURIComponent(previewDocs?.url)}#search=${window.location.search.substr(window.location.search.indexOf('?searchTerm=') + 12)}`} style={{ width: '100%', height: window.innerHeight - 100 }}>
            <param name="type" value="text/html" />
          </object>
        ) : null}
    </DialogContent>
    <DialogActions>
      <Button variant="contained" color="primary" onClick={(e) => downloadFile(previewDocs)}> Download </Button>
      <Button variant="contained" color="primary" onClick={toggleModalFn}> Close </Button>
    </DialogActions>
  </Dialog>
  )
}

export default PreviewDocsModal