import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Tooltip, IconButton, Drawer } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LabelValueCard, RevisionHistory, PageLoader, CustomChip, CustomDropzone, PreviewDocsModal, DeletePopUp } from '../../../../shared_elements';
import { capitalizeFirstLetter, checkApiStatus, getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { fieldValidation } from '../../../../utils_v2/formValidation';
import { addEditInductionCondApi, getInductionCondApi } from '../apiServices';
import { errorCode, addInduction } from '../../';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { addAttachmentApi, deleteAttachmentApi } from '../../../../shared_elements/apiServices';
class InductionCondCRU extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      formSubmitLoader: false,
      pageLoader: false,
      mode: '',
      inductionCond: {},
      error: {},
      previewDocs: '',
      deleteAttachmentModal: false,
      attachedDoc : []
    }
    this.addEditInductionCondApi = addEditInductionCondApi.bind(this);
    this.getInductionCondApi = getInductionCondApi.bind(this);
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      inductionCond: {
        ...prevState.inductionCond,
        [keyParam]: data
      }
    }))
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]: message
      }
    }))
  }
  toggleFnc = (key,status)=>{
    this.setState({[key] : status})
  }
  getDeleteResponse = () => {
    const { inductionCond, deleteItem } = this.state
    const updatedAttachments = inductionCond?.attachments?.filter(
      attachment => attachment.uuid !== deleteItem?.uuid
    );
    this.setState(prevState => ({
      ...prevState,
      inductionCond: {
        ...prevState.inductionCond,
        attachments: updatedAttachments
      }
    }))
    this.props.getResponseBack()
  }
  addEditInductionFn = () => {
    const { inductionCond } = this.state;
    let validationInputs = {
      asset_conditions_for_contract: errorCode['asset_conditions_for_contract'][fieldValidation({ ...errorCode['asset_conditions_for_contractObj'], fieldval: inductionCond.asset_conditions_for_contract && inductionCond.asset_conditions_for_contract.id ? inductionCond.asset_conditions_for_contract.id.toString() : '' })],
      terms: errorCode['terms'][fieldValidation({ ...errorCode['termsObj'], fieldval: inductionCond.terms })],
    };
    if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
      if (this.state.attachedDoc?.length + inductionCond?.attachments?.length > 50) {
        this.props.enqueueSnackbar('Only 50 attachment can be attached.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        return;
      }
      this.setState({ formSubmitLoader: true });
      let payload = Object.assign({}, inductionCond);
      payload = {
        ...payload,
        asset_conditions_for_contract_id: inductionCond.asset_conditions_for_contract.id
      }
      this.addEditInductionCondApi(this.props, payload)
        .then(response => {
          this.setState({ formSubmitLoader: false });
          if (checkApiStatus(response)) {
            if (this.state.attachedDoc?.length > 0) {
              addAttachmentApi(response?.data, this.props, this.state.attachedDoc, "14", this.toggleFnc);
            } else {
              this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
              this.props.getResponseBack();
            }
            this.setState({ modal: false, mode: '', inductionCond: {}, error: {} });
          } else {
            this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          }
        })
    } else {
      this.setState({ error: validationInputs });
    }
  }
  render() {
    const { modal, mode, formSubmitLoader, inductionCond, error, pageLoader } = this.state;
    const { id, m, contractClauseList, contractInfo } = this.props;
    return (
      <Fragment>
        {pageLoader ? <PageLoader /> : null}
        {id ?
          <span onClick={() => this.getInductionCondApi(this.props, id, m)}><Tooltip title="View"><VisibilityIcon color='primary' fontSize='small' style={{ cursor: 'pointer' }} /></Tooltip></span>
          :
          contractInfo?.hdlineInfo?.archived_asset ?
            null :
            <Button onClick={() => this.setState({ modal: true, inductionCond: addInduction, mode: 'add' })} variant="contained" color="primary"><AddIcon fontSize='small' />Add Induction Condition</Button>
        }
        {modal ?
          <Drawer
            open={modal}
            anchor="right"
            onClose={() => this.setState({ modal: false, mode: '', inductionCond: {}, error: {} })}
          >
            <div style={{ width: '670px' }} className="drawer-container">
              <div className="drawer-header" alignItems='center'>
                <span className="left-blk">{`${capitalizeFirstLetter(mode)} Induction Condition`}</span>
                <CloseIcon className="close-icon" onClick={() => this.setState({ modal: false })} />
                {mode !== 'add' ?
                  <ul className="list-inline right-blk">
                    <li className="list-inline-item">
                      <RevisionHistory
                        url='audit/contracts/ctinductionconditionitems/'
                        queryParams={{ action: 1, object_id: id }}
                        buttonType={false}
                      />
                    </li>
                    <li className="list-inline-item">
                      {contractInfo?.hdlineInfo?.archived_asset == false && checkPermission('contracts', 'induction_conditions', 'U') && mode === 'view' && <span className="cta" onClick={() => this.setState({ mode: 'edit' })}><EditIcon className="edit-icon" />Edit</span>}
                      {mode !== 'view' && <span className="cta" onClick={() => this.setState({ mode: 'view' })}><VisibilityIcon className="view-icon" />View</span>}
                    </li>
                  </ul> : null
                }
              </div>
              <div className="drawer-body" style={{position : "sticky"}}>
                <Grid container spacing={2}>
                  {mode == 'view' ?
                    <LabelValueCard md={12} label='Clause' value={inductionCond.asset_conditions_for_contract && inductionCond.asset_conditions_for_contract.name ? inductionCond.asset_conditions_for_contract.name : '--'} /> :
                    <Grid item xs={12} md={12}>
                      <Autocomplete
                        options={contractClauseList}
                        getOptionLabel={option => option.name}
                        value={inductionCond.asset_conditions_for_contract ? inductionCond.asset_conditions_for_contract : null}
                        id="asset_conditions_for_contract"
                        onChange={(e, value) => { this.onFieldChange(e, 'asset_conditions_for_contract', value); }}
                        renderInput={params => <TextField required onFocus={(e) => this.updateErrorField('asset_conditions_for_contract')} error={error.asset_conditions_for_contract ? true : false} helperText={error.asset_conditions_for_contract ? error.asset_conditions_for_contract : ''} {...params} label="Clause" placeholder="Select Clause" margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                      />
                    </Grid>
                  }
                  {mode == 'view' ?
                    <LabelValueCard md={12} label='Terms' value={inductionCond.terms ? (<span dangerouslySetInnerHTML={{ __html: inductionCond.terms.replace(/\n/g, '<br />') }} />) : '--'} /> :
                    <Grid item xs={12}>
                      <TextField
                        required
                        id="terms"
                        label="Terms"
                        fullWidth
                        rows={6}
                        margin="normal"
                        value={inductionCond.terms ? inductionCond.terms : ''}
                        multiline
                        // inputProps={{ maxLength: 500 }}
                        error={error.terms ? true : false}
                        InputLabelProps={{ shrink: true }}
                        helperText={error.terms ? error.terms : ''}
                        onChange={(e) => this.onFieldChange(e, 'terms', e.target.value)}
                        onFocus={(e) => this.updateErrorField('terms')}
                      />
                    </Grid>
                  }
                  {mode == 'view' ?
                    <LabelValueCard md={12} label='Remarks' value={inductionCond.remarks ? (<span dangerouslySetInnerHTML={{ __html: inductionCond.remarks.replace(/\n/g, '<br />') }} />) : '--'} /> :
                    <Grid item xs={12}>
                      <TextField
                        id="remarks"
                        label="Remarks"
                        fullWidth
                        margin="normal"
                        multiline
                        rows="6"
                        value={inductionCond.remarks ? inductionCond.remarks : ''}
                        InputLabelProps={{ shrink: true }}
                        // inputProps={{ maxLength: 255 }}
                        onChange={(e) => this.onFieldChange(e, 'remarks', e.target.value)}
                      />
                    </Grid>
                  }
                  {mode === 'view' ?
                    <div style={{ marginLeft: '8px', marginBottom: '20px' }}>
                      <p style={{ fontSize: '14px', color: 'rgb(160, 160, 160)', fontFamily: "Conv_IBMPlexSans-Regular" }}>Attachment(s) {inductionCond?.attachments?.length>0 ? `[Count : ${inductionCond?.attachments?.length}]` : null}</p>
                      {inductionCond?.attachments?.length ? inductionCond?.attachments && inductionCond?.attachments.map((file, index) => (
                        <CustomChip
                          style={{ marginRight: '4px', marginTop: '7px' }}
                          title={file.name}
                          index={index}
                          label={file.name.substring(0, 14) + '...'}
                          onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ previewDocs: file }) }}
                        />
                      )) : '--'}
                    </div>
                    :
                    (
                      <Grid item xs={12} style={{ marginBottom: '5px' }} className='drag-drop'>
                        <p style={{ fontSize: '13px', color: 'rgb(160, 160, 160)', fontFamily: "Conv_IBMPlexSans-Regular", marginLeft: '8px' }}>Attachment(s)</p>
                        <CustomDropzone
                          onChange={(files) => this.setState({ attachedDoc: files })}
                          componentData={inductionCond}
                          attachedDoc = {this.state.attachedDoc}
                        >
                        </CustomDropzone>
                        {inductionCond?.attachments && inductionCond?.attachments.map((file, index) => (
                          <CustomChip
                            style={{ marginRight: '4px', marginTop: '7px' }}
                            title={file.name}
                            index={index}
                            label={file.name.substring(0, 14) + '...'}
                            onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ previewDocs: file }) }}
                            onDelete={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ deleteAttachmentModal: true, deleteItem: file }); }}
                          />
                        ))}
                      </Grid>)}
                </Grid>
              </div>
              <div className="drawer-footer">
                {mode !== 'view' ?
                  <Button onClick={this.addEditInductionFn} color="primary" variant="contained" disabled={formSubmitLoader}>
                    {formSubmitLoader ? <CircularProgress color="#ffffff" size={24} /> : 'Save Changes'}
                  </Button> : null
                }
                <Button onClick={() => this.setState({ modal: false, mode: '', inductionCond: {}, error: {} })} color="primary" style={mode == 'view' ? { marginLeft: 'auto', paddingLeft: '0px' } : { marginLeft: '8px' }}>Cancel</Button>
              </div>
            </div>
          </Drawer>
          : null
        }
        {
          this.state.previewDocs ? <PreviewDocsModal previewDocs={this.state.previewDocs} toggleModalFn={() => this.setState({ previewDocs: '' })} /> : null
        }
        {this.state.deleteAttachmentModal ?
          <DeletePopUp
            modal={this.state.deleteAttachmentModal}
            toggleModalFn={() => this.setState({ deleteAttachmentModal: false })}
            title="Delete Attachment"
            content={<h4>Are you sure you want to delete?</h4>}
            deleteRecordFn={() => deleteAttachmentApi(this.state.deleteItem, this.toggleFnc, this.getDeleteResponse)}
          /> : null
        }
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(InductionCondCRU));
