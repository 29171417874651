import React, { useEffect, useState } from "react";
import { ContractSideBar, Header } from "../../Elements";
import { assetsType } from "../../../../constants";
import { sExportContractsAc } from "../../../../shared/actionCreators";
import { connect } from "react-redux";
import { ListLoader } from "../../../../shared";
import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Button, Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { globalGetService, globalPostService } from "../../../../utils_v2/globalApiServices";
import { checkApiStatus } from "../../../../utils_v2";
import { STableLoader } from "../../../../shared_elements/loaders";
import { browserHistory } from "react-router";

const ChartsOfAccount = ({ params, exportContract, contractSummaryLoader }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [chartAccInfo, setChartAccInfo] = useState({ list: [], pagination: null });
  useEffect(() => {
    getChartsAccount({}, 'skeletonLoader');
  }, []);

  const getChartsAccount = (query = {}, loaderType) => {
    loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true);
    globalGetService(`/contracts/charts_of_accounts/`, query)
      .then(response => {
        if (checkApiStatus(response)) {
          setChartAccInfo(response.data.data);
        }
        loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false);
      });
  };

  const changeMsn = (evt, exportContract) => {
    let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts';
    browserHistory.push(url);
    params.type = assetsType[evt.asset_type];
    params.aircraft_slug = evt.slug;
  };
    const getThirdPartyApp = (sync) => {
      localStorage.setItem('thirdPartyAccountState', sync);
      setLoading(true)
    globalGetService(`contracts/app-plugin/quickbooks/authenticate/`)
        .then(response => {
          if (checkApiStatus(response)) {
            window.open(response.data?.data?.url, '_blank');
            setLoading(false)
          }
        })
        .catch(() => {
          enqueueSnackbar('Error syncing with QuickBooks', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        });
    };
    const getThirdParty = (data) => {
      debugger
      getThirdPartyApp(data)
    };

  return (
    <div className="contract-container" style={{ display: 'flex', flexDirection: 'column', height: (window.innerHeight - 115) + 'px' }}>
      <div className="mr-rate-wrapper invoice-wrapper" style={{ flexGrow: 1, padding: '', marginLeft: '60px' }}>
        {contractSummaryLoader ? <ListLoader /> : null}

        <Header type="brief" headingText="Lease Management" params={params} changeMsn={changeMsn} exportContract={exportContract} />
        <ContractSideBar application="Cashflow" type="brief" params={params} />
        {skeletonLoader ? <STableLoader count={8} /> :
          <Paper style={{ flexGrow: 1 }}>
            <Paper square style={{ padding: '4px 8px' }}>
              <Grid container spacing={1} alignItems="center">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <ul className="list-inline flex-centered" style={{ float: 'right' }}>
                    <li className="list-inline-item">
                      <Button variant="contained" size="small" onClick={()=>getThirdParty('SyncAccFromQB')} color="primary"> sync accounts</Button>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Paper>
            <Paper style={{ overflow: "auto" }}>
              <Table className="mui-table-format" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Detail Type</TableCell>
                    <TableCell>Account Type</TableCell>
                    <TableCell>Sub Account</TableCell>
                    <TableCell>Classification</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {chartAccInfo.list.map((item, index) =>
                    <TableRow>
                      <TableCell>
                        <div style={{ maxWidth: '180px' }}>{item?.name || '--'}</div>
                      </TableCell>
                      <TableCell>
                        <div>{item?.description || '--'}</div>
                      </TableCell>
                      <TableCell>
                        <div style={{ maxWidth: '180px' }}>{item?.type || '--'}</div>
                      </TableCell>
                      <TableCell>
                        <div style={{ maxWidth: '180px' }}>{item?.sub_type || '--'}</div>
                      </TableCell>
                      <TableCell>
                        <div style={{ maxWidth: '180px' }}>{item?.classification || '--'}</div>
                      </TableCell>
                    </TableRow>)}
                </TableBody>
              </Table>
            </Paper>
          </Paper>}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  contractSummaryLoader: state.sharedReducers.contractSummaryLoader,
});

const mapDispatchToProps = (dispatch) => ({
  exportContract: (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartsOfAccount);
