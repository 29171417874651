import React, { Component } from 'react';
import { withRouter, browserHistory, Link } from 'react-router';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { TableListComp, DeletePopUp, EmptyCollection, PageLoader } from '../../../../shared_elements';
import { InductionCndList, InductionCondCRU } from '../components';
import { Header, ContractSideBar } from '../../../../applications/contracts/Elements';
import { ContractWrapper, LeaseDetialTab } from '../../../Elements';
import { assetsType } from '../../../../constants';
import { STableLoader } from '../../../../shared_elements/loaders';
import { getInductionCondListApi,deleteInductionCondApi } from '../apiServices';
import { getContractClauseAc, sExportContractsAc } from '../../../actionCreators';
import { getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { inductionCondHd } from '../../';
import { trackActivity } from '../../../../utils_v2/mixpanel';
import {contrHdlineInfoAc } from '../../../../applications/contracts/HeadlinesInfo/actionCreators'
import RoleBasedAccess from '../../../../utils/RoleBasedAccess'
class InductionCondition extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader: true,
      pageLoader: false,
      list:[],
      deleteItem:{modal:false, data:null}
    }
    this.getInductionCondListApi = getInductionCondListApi.bind(this);
    this.deleteInductionCondApi = deleteInductionCondApi.bind(this);
  }
  componentDidMount(){
    this.getInductionCondListApi(this.props);
    this.props.getContractClause();
    this.props.fetchContractInfo();
    trackActivity('Lease Management', {
      page_title: 'Induction Condition',
      event_type: 'Page Visited'
    })
  }
  getResponseBack = () => {
    this.getInductionCondListApi(this.props);
  }
  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }
  render(){
    const { list, skeletonLoader, pageLoader, deleteItem } = this.state;
    const { contractClauseList, params, contractSummaryLoader, contractInfo } = this.props;
    return(
      <div className="contract-container">
        <Header type="brief" headingText="Lease Management" params= {this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract} />
        <ContractSideBar application="Induction Conditions" type="brief" params={this.props.params} />
        <div className="contract-content-blk">
          <LeaseDetialTab url='induction-condition' primaryTab='technical-details' secondaryTab='inductionConditions' />
          <ContractWrapper
            revision={true}
            url='audit/contracts/ctinductionconditionitems/'
            queryParams={{action:0, contract:this.props.params.contract_slug}}
            params={params}
            buttonText={ checkPermission('contracts','induction_conditions', 'C') ? <InductionCondCRU  contractInfo={contractInfo} contractClauseList={contractClauseList} getResponseBack={this.getResponseBack} />:null}
          >
            { skeletonLoader ?
              (<STableLoader count={4} />):
              (
                <TableListComp
                  heads={inductionCondHd}
                  data={list.map((item,index) =>
                    <InductionCndList
                      contractInfo={contractInfo}
                      contractClauseList={contractClauseList}
                      getResponseBack={this.getResponseBack}
                      key={index}
                      item={item} 
                      deleteInductionCond={() => this.setState({deleteItem:{modal:true,data:item}})}
                    />
                     
                  )}
                  noRecord={ list.length ? null:
                    <EmptyCollection
                      title="No records found"
                    />
                  }
                />
              )
            }
            { pageLoader || contractSummaryLoader ? <PageLoader />:null}
          </ContractWrapper>
        </div>
        { deleteItem.modal?
          <DeletePopUp
            modal={deleteItem.modal}
            toggleModalFn={() => this.setState({deleteItem:{modal:false, data:null}})}
            title="Delete"
            content={<h4>Are you sure you want to delete?</h4>}
            deleteRecordFn={() => this.deleteInductionCondApi(this.props,deleteItem)}
          />:null
        }
      </div>
    )
  }
}
const mapStateToProps = state => ({
  contractClauseList: state.contractReducer.contractClauseList,
  contractSummaryLoader: state.contractReducer.contractSummaryLoader,
  contractInfo: state.ContrHdlineReducer.contrHdlineInfo,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchContractInfo: () => dispatch(contrHdlineInfoAc(ownProps.params)),
    getContractClause: () => dispatch(getContractClauseAc(ownProps)),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
  }
}
export default withRouter(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(InductionCondition,['contracts','induction_conditions', 'R']))));
