export const CONTR_RESERVE_RATE_LIST = 'CONTR_RESERVE_RATE_LIST';
export const CONTR_RESERVE_RATE_VIEW = 'CONTR_RESERVE_RATE_VIEW';
export const CONTR_RESERVE_RATE_ADD = 'CONTR_RESERVE_RATE_ADD';
export const CONTR_RESERVE_RATE_EDIT = 'CONTR_RESERVE_RATE_EDIT';
export const CONTR_INVOICE_INFO = 'CONTR_INVOICE_INFO';
export const CLOSE_RESERVE_RATE_SIDE_BAR = 'CLOSE_RESERVE_RATE_SIDE_BAR';
export const RESERVE_ACCOUNTS_TYPE = 'RESERVE_ACCOUNTS_TYPE';
export const CONTR_RESERVE_RATE_INVOICE = 'CONTR_RESERVE_RATE_INVOICE';
export const CLOSE_RESERVE_RATE_SIDE_BAR_INVOICE = 'CLOSE_RESERVE_RATE_SIDE_BAR_INVOICE';
export const RESERVE_RATE_LOADER = 'RESERVE_RATE_LOADER';
export const MR_RATE_CRUD = 'MR_RATE_CRUD';
export const TOGGLE_VIEW_EDIT= 'TOGGLE_VIEW_EDIT';
export const MUICP_INFLATION_RATE_LIST = 'MUICP_INFLATION_RATE_LIST';
export const INFLATION_RATE_CRUD = 'INFLATION_RATE_CRUD';
export const MUICP_INFLATION_ADD = 'MUICP_INFLATION_ADD';
export const MUICP_INFLATION_UPDATE='MUICP_INFLATION_UPDATE';
export const INFLATION_RATE_UPDATE = 'INFLATION_RATE_UPDATE';
export const TOGGLE_VIEW_EDIT_INFLATION='TOGGLE_VIEW_EDIT_INFLATION';
export const ANNUAL_RATIO_LIST='ANNUAL_RATIO_LIST';
export const MR_REVISION_HISTORY='MR_REVISION_HISTORY';
export const TOGGLE_REVISION_HISTORY='TOGGLE_REVISION_HISTORY';
export const SET_CHARTS_ACCOUNT_INFO='SET_CHARTS_ACCOUNT_INFO'

