import React from 'react';
import { TableRow, TableCell} from '@material-ui/core';
import { checkPermission, getLocalStorageInfo } from '../../../../utils_v2';
import DeleteIcon from '@material-ui/icons/Delete';
import ObligationCRU from './ObligationCRU';
import ChangeStatusModal from './ChangeStatusModal';
import { AttachmentsRow } from '../../components';

export default function ObligationList({item, previewDetail, approvedUser, pltConstants, getResponseBack, contractInfo,deleteObligation}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{item.obligation_type ? item.obligation_type.label : '--'}</TableCell>
      <TableCell>{item.details ? 
      item.details?.length > 50 ?
      <span>{item?.details?.slice(0, 50)} 
       <ObligationCRU readmore={true} id={item.id} m="V" approvedUser={approvedUser} pltConstants={pltConstants} getResponseBack={getResponseBack} contractInfo={contractInfo} />
        </span> :
      item.details 
      : '--'} </TableCell>
      <TableCell>{item.value ? item.value : '--'}</TableCell>
      <TableCell className={item.status && item.status.value === 0 && (getLocalStorageInfo().user.id == item.to_be_approved_by.id) && checkPermission('contracts','obligations', 'U') ?"flex-centered" : null} style={{ height  : item.status && item.status.value === 0 && (getLocalStorageInfo().user.id == item.to_be_approved_by.id) && checkPermission('contracts','obligations', 'U') ? "70px": null}}>
        {item.status ? item.status.label : '--'}
        { item.status && item.status.value === 0 && (getLocalStorageInfo().user.id == item.to_be_approved_by.id) && checkPermission('contracts','obligations', 'U') ?
          <span style={{marginLeft : "2px"}}>
            <ChangeStatusModal id={item.id} m="V" approvedUser={approvedUser} pltConstants={pltConstants} getResponseBack={getResponseBack}/>
          </span>:null
        }
      </TableCell>
      <TableCell>{item.created_by ? item.created_by.name : '--'}</TableCell>
      <TableCell>{item.to_be_approved_by ? item.to_be_approved_by.name : '--'}</TableCell>
       <TableCell>{item?.attachments?.length> 0 ?<AttachmentsRow attachmentsData={item?.attachments}/>:"--"}</TableCell> 
      <TableCell>
        <ObligationCRU id={item.id} m="V" approvedUser={approvedUser} pltConstants={pltConstants} getResponseBack={getResponseBack} contractInfo={contractInfo} />
        {checkPermission('contracts','obligations', 'D') ? <DeleteIcon fontSize='small' color='secondary' onClick={()=>deleteObligation(item.id)}/>: null}
        </TableCell>
    </TableRow>
  )
}
