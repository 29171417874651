import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Tooltip, IconButton, Drawer, FormControlLabel, Checkbox, Chip, } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LabelValueCard, RevisionHistory, PageLoader, DeletePopUp } from '../../../../shared_elements';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { capitalizeFirstLetter, checkApiStatus, getLocalStorageInfo, checkPermission, toastFlashMessage, downloadFile } from '../../../../utils_v2';
import { addEditReturnConApi, getReturnConApi } from '../apiServices';
import { fieldValidation } from '../../../../utils_v2/formValidation';
import { errorCodeReturnCondition, addReturnCondition } from '../../';
import { DropzoneArea } from 'material-ui-dropzone';
import { globalDeleteService, globalPostService } from '../../../../utils_v2/globalApiServices';
import { assetId } from '../../../../constants';
import zIndex from '@material-ui/core/styles/zIndex';
class ReturnCondCRU extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      formSubmitLoader: false,
      pageLoader: false,
      mode: '',
      returnCond: {},
      error: {},
      attatchedDoc:[],
      previewDocs: '',
      deleteAttachmentModal: false, 
      deleteItem: null,
    }
    this.addEditReturnConApi = addEditReturnConApi.bind(this);
    this.getReturnConApi = getReturnConApi.bind(this);
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      returnCond: {
        ...prevState.returnCond,
        [keyParam]: data
      }
    }))
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]: message
      }
    }))
  }
  addRtnAttachment = (data) => {
    const { props, params } = this.props;
    const { attatchedDoc, returnCond } = this.state;
    let formdata = new FormData();

    attatchedDoc.forEach(item => {
      formdata.append('files', item);
    });

    formdata.append('asset_type', assetId[params?.type])
    formdata.append('asset_slug', params.aircraft_slug);
    formdata.append('contract_slug', params.contract_slug);
    formdata.append('model_type', '9');
    formdata.append('object_id', data?.id)  
    this.setState({ pageLoader: true });
    globalPostService(`contracts/attachments/`, formdata)
      .then(response => {
        if (response?.data?.statusCode === 200) {
          toastFlashMessage(response.data.message, 'success');
        } else {
          toastFlashMessage(response.data.message, 'error');
        }
        this.setState({ pageLoader: false });
        this.props.getResponseBack();
      });
  }
  addEditReturnFn = () => {
    const { returnCond } = this.state;
    let validationInputs = {
      asset_conditions_for_contract: errorCodeReturnCondition['asset_conditions_for_contract'][fieldValidation({ ...errorCodeReturnCondition['asset_conditions_for_contractObj'], fieldval: returnCond.asset_conditions_for_contract && returnCond.asset_conditions_for_contract.id ? returnCond.asset_conditions_for_contract.id.toString() : '' })],
      terms: errorCodeReturnCondition['terms'][fieldValidation({ ...errorCodeReturnCondition['termsObj'], fieldval: returnCond.terms })],
    };
    if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
      if (this.state.attatchedDoc?.length + returnCond?.pooling_language?.length>50) {
        this.props.enqueueSnackbar('Only 50 attachment can be attached.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        return; 
      }
      this.setState({ formSubmitLoader: true });
      let payload = Object.assign({}, returnCond);
      payload = {
        ...payload,
        asset_conditions_for_contract_id: returnCond.asset_conditions_for_contract.id
      }
      this.addEditReturnConApi(this.props, payload)
        .then(response => {
          if (checkApiStatus(response)) {
            if (this.state.attatchedDoc?.length) {
                this.addRtnAttachment(response?.data?.data);
                this.setState({ modal: false, mode: '', returnCond: {}, error: {} });
            } else {
              this.setState({ modal: false, mode: '', returnCond: {}, error: {} });
            }
            this.props.getResponseBack();
            this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          } else {
            this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          }
        })
    } else {
      this.setState({ error: validationInputs });
    }
  }
  previewDoc(item) {
    this.setState({ previewDocs: item })
  }
  handleDelete = (item) => {
    this.setState({ deleteAttachmentModal: true, deleteItem: item });
  };
  
  deleteAttachment = (item) => {
    const { returnCond , deleteItem} = this.state;
      this.setState({ deleteAttachmentModal: false, pageLoader: true });
      globalDeleteService(`contracts/attachments/${deleteItem.uuid}/`)
      .then((response) => {
        this.setState({ pageLoader: false }); 
        if (response?.data?.statusCode === 200) {
          const updatedPoolingLanguage = returnCond.pooling_language.filter((file) => file.uuid !== deleteItem.uuid);
          this.setState({returnCond: { ...returnCond, pooling_language: updatedPoolingLanguage }});
          this.getReturnConApi(this.props, returnCond.id); 
          this.props.getResponseBack();
          toastFlashMessage(response?.data?.message, 'success');
        } else {
          toastFlashMessage(response?.data?.message, 'error');
        }
      })

  };
  render() {
    const { modal, formSubmitLoader, mode, returnCond, error, pageLoader, previewDocs, deleteAttachmentModal } = this.state;
    const { id, m, contractClauseList, contractInfo } = this.props;
    const updatedFileLimit = id ? 50 -this.state.attatchedDoc?.length - returnCond?.pooling_language?.length : 50
    return (
      <Fragment>
        {pageLoader ? <PageLoader /> : null}
        {id ?
          <span onClick={() => this.getReturnConApi(this.props, id, m)}><Tooltip title="View"><VisibilityIcon color='primary' fontSize='small' style={{cursor:'pointer'}}/></Tooltip></span>
          :
          contractInfo?.hdlineInfo?.archived_asset ?
          null:
          <Button onClick={() => this.setState({ modal: true, returnCond: addReturnCondition, mode: 'add' })} variant="contained" color="primary"><AddIcon fontSize='small' />Add Return Condition</Button>
        }
        {modal ?
          <Drawer
            open={modal}
            anchor="right"
            onClose={() => this.setState({ modal: false, mode: '', returnCond: {}, error: {} })}
          >
            <div style={{ width: '670px' }} className="drawer-container" >
              <div className="drawer-header" alignItems='center'>
                <span className="left-blk">{`${capitalizeFirstLetter(mode)} Return Condition`}</span>
                <CloseIcon className="close-icon" onClick={() => this.setState({ modal: false })} />
                {mode !== 'add' ?
                  <ul className="list-inline right-blk">
                    <li className="list-inline-item">
                      <RevisionHistory
                        url='audit/contracts/ctreturnconditionitems/'
                        queryParams={{ action: 1, object_id: id }}
                        buttonType={false}
                      />
                    </li>
                    <li className="list-inline-item">
                      { contractInfo?.hdlineInfo?.archived_asset == false && checkPermission('contracts','return_conditions', 'U') && mode === 'view' && <span className="cta" onClick={() => this.setState({ mode: 'edit' })}><EditIcon className="edit-icon" />Edit</span>}
                      {mode !== 'view' && <span className="cta" onClick={() => this.setState({ mode: 'view' })}><VisibilityIcon className="view-icon" />View</span>}
                    </li>
                  </ul> : null
                }
              </div>
              <div className="drawer-body" style={{position:'sticky'}}>
                <Grid container spacing={2}>
                  {mode === 'view' ?
                    <LabelValueCard md={6} label='Clause' value={returnCond.asset_conditions_for_contract && returnCond.asset_conditions_for_contract.name ? returnCond.asset_conditions_for_contract.name : '--'} /> :
                    <Grid item xs={12} md={12}>
                      <Autocomplete
                        options={contractClauseList}
                        getOptionLabel={option => option.name}
                        value={returnCond.asset_conditions_for_contract ? returnCond.asset_conditions_for_contract : null}
                        id="asset_conditions_for_contract"
                        onChange={(e, value) => { this.onFieldChange(e, 'asset_conditions_for_contract', value); }}
                        renderInput={params => <TextField required onFocus={(e) => this.updateErrorField('asset_conditions_for_contract', '')} error={error.asset_conditions_for_contract ? true : false} helperText={error.asset_conditions_for_contract ? error.asset_conditions_for_contract : ''} {...params} label="Clause" placeholder="Select Clause" margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                      />
                    </Grid>
                  }
                  {mode === 'view' ?
                    <LabelValueCard md={12} label="Terms" value={ returnCond.terms? ( <span dangerouslySetInnerHTML={{ __html: returnCond.terms.replace(/\n/g, '<br />')}}/>): '--'}/>:
                    <Grid item xs={12}>
                      <TextField
                        required
                        id="terms"
                        label="Terms"
                        fullWidth
                        margin="normal"
                        multiline
                       // inputProps={{ maxLength: 1000 }}
                        value={returnCond.terms}
                        error={error.terms ? true : false}
                        InputLabelProps={{ shrink: true }}
                        helperText={error.terms ? error.terms : ''}
                        onChange={(e) => this.onFieldChange(e, 'terms', e.target.value)}
                        onFocus={(e) => this.updateErrorField('terms', '')}
                      />
                    </Grid>
                  }
                  {mode === 'view' ?
                    <LabelValueCard md={12} label='Exceptions' value={returnCond.exceptions_done ? returnCond.exceptions_done : '--'} /> :
                    <Grid item xs={12}>
                      <TextField
                        id="exceptions_done"
                        label="Exceptions"
                        fullWidth
                        margin="normal"
                        multiline
                        rows="4"
                        inputProps={{ maxLength: 255 }}
                        value={returnCond.exceptions_done}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => this.onFieldChange(e, 'exceptions_done', e.target.value)}
                      />
                    </Grid>
                  }
                  {mode === 'view' ?
                    <LabelValueCard md={12} label="Remarks" value={ returnCond.remarks? ( <span dangerouslySetInnerHTML={{ __html: returnCond.remarks.replace(/\n/g, '<br />')}}/>): '--'}/>:
                    <Grid item xs={12}>
                      <TextField
                        id="remarks"
                        label="Remarks"
                        fullWidth
                        margin="normal"
                        multiline
                        rows="4"
                        // inputProps={{ maxLength: 1000 }}
                        value={returnCond.remarks}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => this.onFieldChange(e, 'remarks', e.target.value)}
                      />
                    </Grid>
                  }
                  {mode === 'view' ?
                    <LabelValueCard md={12} label='Flag this Return Condition' value={returnCond.flags ? 'Yes' : 'No'} /> :
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox color="primary" checked={returnCond.flags} onChange={(e) => this.onFieldChange(e, 'flags', e.target.checked)} value="Flag this Return Condition" />
                        }
                        label="Flag this Return Condition"
                      />
                    </Grid>
                  }
                  {mode === 'view'  ?
                    <div style={{ marginLeft: '8px', marginBottom: '20px' }}>
                      <p style={{ fontSize: '14px', color: 'rgb(160, 160, 160)',fontFamily:"Conv_IBMPlexSans-Regular" }}>Attachment(s) {returnCond?.pooling_language?.length>0 ? `[Count : ${returnCond?.pooling_language?.length}]` : null}</p>
                      {returnCond.pooling_language?.length>0 ? returnCond.pooling_language.map((file, index) => (
                        <Tooltip key={index} title={`${file.name}`} arrow placement="top">
                          <Chip
                            style={{ marginRight: '4px', marginTop: '7px' }}
                            variant="outlined"
                            size="small"
                            label={file.name.substring(0, 14) + '...'}
                            clickable
                            color="primary"
                            onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.previewDoc(file) }}
                          />
                        </Tooltip>
                      )) : "--"}
                    </div>
                    :
                    <Grid item xs={12} style={{ marginBottom: '20px' }} className='drag-drop'>
                      <p style={{ fontSize: '14px', color: 'rgb(160, 160, 160)',fontFamily:"Conv_IBMPlexSans-Regular", marginLeft:'2px' }}>Attachment(s)</p>
                      <DropzoneArea
                        filesLimit={(50 - (returnCond?.pooling_language?.length || 0))}
                        acceptedFiles={['.xls', '.csv', '.xlsx', '.docx', '.pdf', '.jpg', '.jpeg', '.png']}
                        showPreviewsInDropzone={false}
                        showPreviews={true}
                        maxFileSize={5000000000000}
                        useChipsForPreview
                        dropzoneText={<p style={{ fontSize: '12px' }}>Drag & Drop Documents OR Click Here</p>}
                        maxWidth="sm"
                        showAlerts={['error', 'info']}
                        onChange={(files) => this.setState({ attatchedDoc: files })}
                        alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' }, style:{marginTop:'30px'} }}
                      >
                      </DropzoneArea>
                      <h6 style={{ marginTop: '5px', fontSize: '11px' }}>
                        <span><b>Note:</b>Only pdf, docx, csv, xls, xlsx, jpg, jpeg and png files are supported (Maximum Upto 50 files.)</span>
                        <br />
                        {id ? <>
                          Files uploaded <b style={{ font: 'menu' }}>({returnCond?.pooling_language?.length || 0})</b>.
                          {updatedFileLimit < 0 ? <span > You have exceeded file limit, Please remove  <b style={{ font: 'menu', color: "red" }}>({Math.abs(updatedFileLimit)})</b> files.</span> : <span> Files remaining <b style={{ font: 'menu' }}>({updatedFileLimit})</b>.</span>}
                        </> : null}
                      </h6> 
                      {returnCond.pooling_language&&returnCond.pooling_language.map((file, index) => (
                        <Tooltip key={index} title={`${file.name}`} arrow placement="top">
                          <Chip
                            style={{ marginRight: '4px', marginTop: '7px' }}
                            variant="outlined"
                            size="small"
                            label={file.name.substring(0, 14) + '...'}
                            clickable
                            color="primary"
                            onClick={(e) => {e.preventDefault(); e.stopPropagation();this.previewDoc(file)}}
                            onDelete={(e) => { e.preventDefault(); e.stopPropagation(); this.handleDelete(file) }}
                          />
                        </Tooltip>
                      ))}
                    </Grid>}
                </Grid>
              </div>
              <div className="drawer-footer">
                {mode !== 'view' ?
                  <Button onClick={this.addEditReturnFn} color="primary" variant="contained" >Save Changes</Button> : null
                }
                <Button onClick={() => this.setState({ modal: false, mode: '', returnCond: {}, error: {} })} color="primary" style={mode == 'view' ? { marginLeft: 'auto', paddingLeft: '0px' } : { marginLeft: '8px' }}>Cancel</Button>
              </div>
            </div>
          </Drawer> : null
        }
        {
          previewDocs ? (
            <Dialog open={true} fullScreen >
              <DialogTitle>{previewDocs.name}</DialogTitle>
              <DialogContent style={{ height: '' }} >
                {previewDocs.extension === 'csv' ? (
                  <object id="myObject" data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/xls_viewer/index.html?file=${encodeURIComponent(previewDocs.url)}`} style={{width: '100%', height: window.innerHeight - 100}}/>
                ) : ['xls', 'xlsx', 'docx'].includes(previewDocs.extension.toLowerCase()) ? (
                  <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(previewDocs.url)}`} width='100%' height={window.innerHeight} frameborder='0'/>
                ) : ['jpg', 'jpeg', 'png'].includes(previewDocs.extension.toLowerCase()) ? (
                  <img
                    src={previewDocs?.url}
                    alt="Attachment"
                    style={{ width:'100%', height: 'auto' }}
                  />) : previewDocs.extension === 'pdf' ? (
                  <object type="text/html" data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${encodeURIComponent(previewDocs.url)}#search=${window.location.search.substr(window.location.search.indexOf('?searchTerm=') + 12)}`} style={{width: '100%', height: window.innerHeight - 100}}>
                    <param name="type" value="text/html" />
                  </object>
                ) : null}
              </DialogContent>
              <DialogActions>
                <Button variant="contained" color="primary" onClick={(e) => downloadFile(previewDocs)}> Download </Button>
                <Button variant="contained" color="primary" onClick={(e) => this.setState({ previewDocs: '' })}> Close </Button>
              </DialogActions>
            </Dialog>
          ) : null
        }
        {deleteAttachmentModal ?
          <DeletePopUp
            modal={deleteAttachmentModal}
            toggleModalFn={() => this.setState({ deleteAttachmentModal: false })}
            title="Delete Attachment"
            content={<h4>Are you sure you want to delete?</h4>}
            deleteRecordFn={() => this.deleteAttachment()}
          /> : null
        }
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(ReturnCondCRU));
