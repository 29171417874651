import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { displayDateFormat, imgStoragePath } from '../../../../constants';
import HDLabelCard from './HDLabelCard';
import { getLocalStorageInfo } from '../../../../utils';
import { checkPermission, downloadFile, toastFlashMessage } from '../../../../utils_v2';
import editIcon from '../../../../shared/assets/img/edit_icon.svg';
import { ToolTipHover, InfoIcon } from '../../../../shared';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Tooltip } from '@material-ui/core';

export default class HorizontalInfoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewDocs: '',
      showAll: false,
      openAttachment: false
    }
  }
  previewDoc(item) {
    this.setState({ previewDocs: item })
  }
  toggleAttachments = () => {
    this.setState((prevState) => ({ openAttachment: !prevState.openAttachment }));
  };
  render() {
    const { leaseInfo, autoInvoiceOn } = this.props;
    const { previewDocs, deleteAttachmentModal, pageLoader, showAll, openAttachment } = this.state
    const attachmentsToShow = Array.isArray(leaseInfo?.acceptance_certificate) ? (showAll ? leaseInfo.acceptance_certificate : leaseInfo.acceptance_certificate.slice(0, 2)) : [];
    return (
      <div className="horizontal-card card-block">
        {pageLoader ? <pageLoader /> : null}
        <h5 className="para"> Lease Information
          {leaseInfo?.archived_asset == false ?
            checkPermission('contracts', 'lease_details', 'U') ?
              <img onClick={this.props.toggleEditSideBar} src={editIcon} alt="img" className="blue-img" width="16" /> :
              null : null
          }
        </h5>
        <Row>
          <HDLabelCard col="4" label="Lease Type" value={leaseInfo.lease_type && leaseInfo.lease_type.label ? leaseInfo.lease_type.label : '--'} />
          <HDLabelCard col="4" label="Lease Type ( Accounting )" value={leaseInfo.lease_type_accounting && leaseInfo.lease_type_accounting.label ? leaseInfo.lease_type_accounting.label : '--'} />
          <HDLabelCard col="4" label="Lease Number" value={leaseInfo.contract_number ? leaseInfo.contract_number : '--'} />
          <HDLabelCard col="4" label="Lease Definition of Lease Period" value={leaseInfo.contractual_definition_of_lease_period ? leaseInfo.contractual_definition_of_lease_period : '--'} />
          {leaseInfo.rolling_lease_status && <HDLabelCard col="4" label="Rolling Lease (Enabled)" value={leaseInfo.rolling_lease_span === 1 ? leaseInfo.rolling_lease_duration + ' ' + 'Days' : leaseInfo.rolling_lease_span === 2 ? leaseInfo.rolling_lease_duration + ' ' + 'Month' : '--'} />}
          <HDLabelCard col="4" label="Lease Duration Type" value={leaseInfo?.lease_duration_type?.label ? leaseInfo.lease_duration_type.label : '--'} />
          <HDLabelCard col="4" label="Lease Start Date" value={leaseInfo?.contractual_start_date ? moment(leaseInfo?.contractual_start_date).format(displayDateFormat) : '--'} />
          {
            leaseInfo.lease_duration_type.value == 1 ?
              <>
                <HDLabelCard col="4" label="Lease End Date" value={leaseInfo.contractual_end_date ? moment(leaseInfo.contractual_end_date).format(displayDateFormat) : '--'} />
                <HDLabelCard col="4" label="Lease Term" value={leaseInfo.lease_term ? leaseInfo.lease_term + ' Months' : '--'} />
              </>
              :
              leaseInfo.lease_duration_type.value == 2 ?
                <>
                  <HDLabelCard col="4" label="Start TSN" value={leaseInfo.tsn ? leaseInfo.tsn : '--'} />
                  <HDLabelCard col="4" label="Lease End Date" value={leaseInfo.contractual_end_date ? moment(leaseInfo.contractual_end_date).format(displayDateFormat) : '--'} />
                  <HDLabelCard col="4" label="Lease Term" value={leaseInfo.lease_term ? leaseInfo.lease_term + ' Months' : '--'} />
                  <HDLabelCard col="4" label="Lease Duration Hours" value={leaseInfo.lease_duration_hours ? leaseInfo.lease_duration_hours + ' Hours' : '--'} />
                </>
                :
                <>
                  <HDLabelCard col="4" label="Start TSN" value={leaseInfo.tsn ? leaseInfo.tsn : '--'} />
                  <HDLabelCard col="4" label="Lease Duration Hours" value={leaseInfo.lease_duration_hours ? leaseInfo.lease_duration_hours + ' Hours' : '--'} />
                  <HDLabelCard col="4" label="Time between Overhaul" value={leaseInfo.time_between_overhaul ? leaseInfo.time_between_overhaul : '--'} />
                  <HDLabelCard col="4" label="Last Overhaul TSN" value={leaseInfo.last_overhaul_tsn ? leaseInfo.last_overhaul_tsn : '--'} />
                </>
          }
          <HDLabelCard col="4" label="Conditions Precedent" value={leaseInfo.conditions_precedence ? leaseInfo.conditions_precedence : '--'} />
          <HDLabelCard col="4" label="Alternate Dispute Redressal" value={leaseInfo.alternate_dispute_redressal ? leaseInfo.alternate_dispute_redressal : '--'} />
          <HDLabelCard spanStyle={{ wordBreak: 'break-all' }} col="4" label="Rental Account Number" value={leaseInfo.rental_account_number ? leaseInfo.rental_account_number : '--'} />
          <HDLabelCard spanStyle={{ wordBreak: 'break-all' }} col="4" label="MR Account Number" value={leaseInfo.mr_account_number ? leaseInfo.mr_account_number : '--'} />
          {/* <HDLabelCard spanStyle={{ wordBreak: 'break-all' }} col="4" label="Supplement Date" value={leaseInfo.supplement_date ? moment(leaseInfo.supplement_date).format(displayDateFormat) : '--'} /> */}
          <Col md="4" className="horizontal-inner-block">
            <h6>Insurance Type <InfoIcon id={"insurance"} />
              <ToolTipHover placement="top" tagetId={"insurance"}>
                Contingent or Possessed
              </ToolTipHover>
            </h6>
            <span style={{ wordBreak: 'break-all' }}>{leaseInfo.insurance_type ? leaseInfo.insurance_type : '--'}</span>
          </Col>
          {autoInvoiceOn.auto_invoice_generate ? <HDLabelCard spanStyle={{ wordBreak: 'break-all' }} col="4" label="Default Bank" value={leaseInfo.default_bank ? leaseInfo.default_bank.name : '--'} /> : null}

          {
            leaseInfo.actual_lease_date ?
              <HDLabelCard col="4" label="Actual Lease End Date" value={leaseInfo.actual_lease_date ? moment(leaseInfo.actual_lease_date).format(displayDateFormat) : '--'} />
              : null
          }
         
            <Col md="4" className="horizontal-inner-block">
              <h6>Attachment(s)</h6>
              {attachmentsToShow?.length ? attachmentsToShow && attachmentsToShow.map((file, index) => (
                <Tooltip key={index} title={`${file.name}`} arrow placement="top">
                  <Chip
                    style={{ marginRight: '4px', marginTop: '7px', height: '15px', fontSize: '10px' }}
                    variant="outlined"
                    size="small"
                    label={<p style={{ fontSize: '10px' }}>{file.name.substring(0, 14) + '...'}</p>}
                    clickable
                    color="primary"
                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.previewDoc(file) }}
                  />
                </Tooltip>
              )) : '--'}
              {leaseInfo?.acceptance_certificate?.length > 2 && (
                <div style={{ marginTop: '10px' }}>
                  <Chip
                    label={<p style={{color:'#fff',fontSize:'10px'}}>{openAttachment ? 'Show Less' : 'Show More'}</p>}
                    clickable
                    color='primary'
                    onClick={this.toggleAttachments}
                    style={{ cursor: 'pointer', height: '15px', fontSize: '9px' }}
                  />
                </div>
              )}
            </Col> 
        </Row>
        {previewDocs ? (
          <Dialog open={true} fullScreen >
            <DialogTitle>{previewDocs.name}</DialogTitle>
            <DialogContent style={{ height: '' }} >
              {previewDocs.extension === 'csv' ? (
                <object id="myObject" data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/xls_viewer/index.html?file=${encodeURIComponent(previewDocs.url)}`} style={{ width: '100%', height: window.innerHeight - 100 }} />
              ) : ['xls', 'xlsx', 'docx'].includes(previewDocs.extension.toLowerCase()) ? (
                <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(previewDocs.url)}`} width='100%' height={window.innerHeight} frameborder='0' />
              ) : ['jpg', 'jpeg', 'png'].includes(previewDocs.extension.toLowerCase()) ? (
                <img
                  src={previewDocs?.url}
                  alt="Attachment"
                  style={{ width: '100%', height: 'auto' }}
                />) : previewDocs.extension === 'pdf' ? (
                  <object type="text/html" data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${encodeURIComponent(previewDocs.url)}#search=${window.location.search.substr(window.location.search.indexOf('?searchTerm=') + 12)}`} style={{ width: '100%', height: window.innerHeight - 100 }}>
                    <param name="type" value="text/html" />
                  </object>
                ) : null}
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary"onClick={(e) => downloadFile(previewDocs)}> Download </Button>
              <Button variant="contained" color="primary" onClick={(e) => this.setState({ previewDocs: '' })}> Close </Button>
            </DialogActions>
          </Dialog>
        ) : null
        }
        {openAttachment &&
          <Dialog open={openAttachment} className='mou-dialog'>
            <DialogTitle>Attachment(s)</DialogTitle>
            <DialogContent dividers>
              <List>
                {leaseInfo?.acceptance_certificate?.map((file, index) => (
                  <ListItem button key={index} style={{ backgroundColor: index % 2 === 0 ? '#ebeff6' : '#fff' }} onClick={() => this.previewDoc(file)}>
                    <ListItemText primary={file.name} />
                  </ListItem>
                ))}
              </List>
            </DialogContent>
            <DialogActions>
              <Button variant='outlined' color='primary' size='small' onClick={this.toggleAttachments}>Close</Button>
            </DialogActions>
          </Dialog>}
      </div>
    )
  }
}
