import React, { useEffect, useState } from 'react'
import { Button, Checkbox, CircularProgress, Dialog, Drawer, FormControlLabel, Grid, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { regexConstants } from '../../../../constants/regEx';
import { globalPostService, globalPutService } from '../../../../globalServices';
import { checkApiStatus } from '../../../../utils_v2';
import { useSnackbar } from 'notistack';
import { FieldLabel } from '../../../../applications/contracts/Elements';

export default function OperationCRU({ mode, setModal, modal, props, getResponseBack }) {
    const [region, setRegion] = useState({status:true});
    const [isloading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    
    useEffect(()=>{
            if(modal?.data){
                setRegion(modal?.data)
            }
            setError({})
    },[modal?.data])
    const onFieldChange = (key, value) => {
        setRegion({ ...region, [key]: value })
    }
    const addEditRegion = () => {
        let validateNewInput={
            name: region?.name?.trim() ?'' : 'Please enter Region name ',
            escalation_percentage: region?.escalation_percentage?.trim() ? '' : 'Please enter Escalation percentage',
        }
        if(Object.values(validateNewInput).some((item) => item !== '')){
            setError(validateNewInput);
        }
        else{
        if(region?.id){
            globalPutService(`contracts/${props.params.contract_slug}/regions/${region?.id}/`, region)
            .then(response=>{
                if(checkApiStatus(response)){
                    setModal({flag:false});
                    getResponseBack();
                    enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                }
            })
        }
        else{
            globalPostService(`contracts/${props.params.contract_slug}/regions/`, region)
            .then(response=>{
                if(checkApiStatus(response)){
                    setModal({flag:false});
                    getResponseBack();
                    enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                }
            })
        }}
    }
    return (
        <div>
            <Button onClick={() => {setError({}); setRegion({ status: true }); setModal({ flag: true }) }} variant="contained" color="primary">Add Region</Button>
            {modal?.flag ?
                <Drawer
                    open={modal?.flag}
                    anchor='right'>
                    <div className='drawer-container' style={{ width: '670px' }}>
                        <div className="drawer-header">
                            <span className="left-blk">{`Add Region`}</span>
                            <CloseIcon className="close-icon" onClick={() => { setModal({}) }} />
                        </div>
                        <div className="drawer-body">
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        disabled={modal?.mode === 'view'}
                                        placeholder='Enter Region name'
                                        id="region"
                                        fullWidth
                                        margin="normal"
                                        error={error.name || false}
                                        helperText={error.name || ''}
                                        onFocus={() => setError({ ...error, name: '' })}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 250 }}
                                        label="Region name"
                                        value={region?.name || ''}
                                        onChange={(e) => onFieldChange('name', e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        disabled={modal?.mode === 'view'}
                                        label="Escalation Percentage"
                                        placeholder='Enter Percentage of Escalation'
                                        id="region"
                                        error={error.escalation_percentage || false}
                                        helperText={error.escalation_percentage || ''}
                                        onFocus={() => setError({ ...error, escalation_percentage: '' })}
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: 250 }}
                                        value={region?.escalation_percentage || ''}
                                        onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('escalation_percentage', e.target.value) : e.preventDefault()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FieldLabel 
                                    className='label'
                                    ><FormControlLabel
                                    style={{ marginRight:'0px', fontSize: "12px" }}
                                    control={
                                        <Checkbox
                                            disabled={modal?.mode === 'view'}   
                                            color="primary"
                                            size='small'
                                            checked={region.status ? region.status : false}
                                            onChange={(e) => onFieldChange('status', e.target.checked)}
                                            value="Status"
                                        />
                                    }
                                    />  Enable Region based Escalation    
                                    </FieldLabel>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="drawer-footer">
                            {modal?.mode !== 'view' ?
                                <Button onClick={addEditRegion} color="primary" variant="contained" disabled={isloading}>
                                    {isloading ? <CircularProgress color="#ffffff" size={24} /> : 'Save Changes'}
                                </Button> : null}
                            <Button onClick={() => {setRegion({});setModal({ modal: false, mode: '' })}} color="primary" style={mode == 'view' ? { marginLeft: 'auto', paddingLeft: '0px' } : { marginLeft: '8px' }}>Cancel</Button>
                        </div>
                    </div>
                </Drawer>
                : null}
        </div>
    )
}
