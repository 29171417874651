export const RENTAL_INFO = 'RENTAL_INFO';
export const EDIT_RENTAL_SUCCESS = 'EDIT_RENTAL_SUCCESS';
export const RENTAL_VIEW_DETIAL = 'RENTAL_VIEW_DETIAL';
export const TOGGLE_RENTAL_INVOICE_SIDE_BAR = 'TOGGLE_RENTAL_INVOICE_SIDE_BAR';
export const RENTAL_INVOICE_VIEW = 'RENTAL_INVOICE_VIEW';
export const RENTAL_INVOICE_EDIT = 'RENTAL_INVOICE_EDIT';
export const LEASE_RENTAL_LOADER = 'LEASE_RENTAL_LOADER';
export const LEASE_RENTAL_CRUD = 'LEASE_RENTAL_CRUD';
export const LEASE_RENTAL_ADD_SUCCESS = 'LEASE_RENTAL_ADD_SUCCESS';
export const TOGGLE_VIEW_EDIT = 'TOGGLE_VIEW_EDIT';
export const DELETE_LEASE_RENTAL = 'DELETE_LEASE_RENTA';
export const MODAL_TOGGLE = 'MODAL_TOGGLE';
export const LEASE_RENTAL_SHEET_SUCCESS = 'LEASE_RENTAL_SHEET_SUCCESS';
export const LEASE_RENTAL_PATH='LEASE_RENTAL_PATH';
export const SHEET_LOADER='SHEET_LOADER';
export const RENTAL_REVISION_HISTORY='RENTAL_REVISION_HISTORY';
export const TOGGLE_REVISION_HISTORY='TOGGLE_REVISION_HISTORY';
export const SET_CHARTS_ACCOUNT_INFO='SET_CHARTS_ACCOUNT_INFO'
