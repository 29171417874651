import React from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { Button, Grid, TextField,  Drawer } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { capitalizeFirstLetter } from '../../../../utils_v2';

export function CapeTownCRU({ mode, error, capeTownConv, modal, addEditcapeTownConvFn, toggleModalFn }) {
  return (
    <form onSubmit={(e) => { e.preventDefault(); addEditcapeTownConvFn() }}>
      <Drawer
        open={modal}
        anchor="right"
        onClose={toggleModalFn}
      >
        <div style={{ width: '670px' }} className="drawer-container">
          <div className="drawer-header" alignItems='center'>
            <span className="left-blk">{`${capitalizeFirstLetter(mode)} Cape Town Convention`}</span>
            <CloseIcon className="close-icon" onClick={() => this.setState({ modal: false })} />
          </div>
        </div>
        <div className="drawer-body" style={{ position: "sticky" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="description"
                label="Description"
                fullWidth
                margin="normal"
                value={capeTownConv.description}
                error={error.description ? true : false}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
         
        </div>
        {mode !== 'view' ?
          <div className="drawer-footer">
            <Button onClick={toggleModalFn} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save Changes
            </Button>
          </div> : null
        }
      </Drawer>
    </form>
  )
}

export default withRouter(withSnackbar(CapeTownCRU));
