import React from 'react';
import { TableRow, TableCell, Table} from '@material-ui/core';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import { displayDateFormatShort } from '../../../../constants';
import NumericLabel from 'react-pretty-numbers';
import { getCurrencyFormat, checkPermission } from '../../../../utils_v2';
import ClaimCRU from './ClaimCRU'
import { AttachmentsRow } from '../../components';
export default function ClaimList({item, pltConstants, getResponseBack, approvers, contractInfo,deleteClaimInsurance}){
  return(
    
    <TableRow hover tabIndex={-1} style={{position:'relative'}}>
      <TableCell>{item.insurance_number}</TableCell>
      <TableCell>{item.insurer}</TableCell>
      <TableCell>{item.claim_summary}</TableCell>
      <TableCell>{item.date ? moment(item.date).format(displayDateFormatShort):'--'}</TableCell>
      <TableCell>{item.status.label}</TableCell>
      <TableCell>{item.amount? <NumericLabel params={getCurrencyFormat()}>{item.amount}</NumericLabel>:'--'}</TableCell>
      <TableCell>{item?.attachments?.length> 0 ?<AttachmentsRow attachmentsData={item?.attachments}/>:"--"}</TableCell> 
      <TableCell>
        <ClaimCRU id={item.id} m="V" pltConstants={pltConstants} getResponseBack={getResponseBack} approvers={approvers} contractInfo={contractInfo}/>
        {checkPermission('contracts','insurance', 'D') ? <DeleteIcon fontSize='small' color='secondary' style={{cursor:'pointer'}} onClick={()=>deleteClaimInsurance(item.id)} />: null}
        </TableCell>
    </TableRow>
  )
}
