import React from 'react'
import { Chip, Tooltip } from '@material-ui/core';


const CustomChip = ({index,title,label,onClick,onDelete,style,variant}) => {
  return (
        <Tooltip key={index} title={title} arrow placement="top">
          <Chip
            style={style ? style : null}
            variant={variant  ? variant : "outlined"}
            size="small"
            label={label}
            clickable
            color="primary"
            onClick={onClick}
            onDelete={onDelete ? onDelete : null}
          />
        </Tooltip>
  )
}

export default CustomChip