import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { Button, Grid, TextField, CircularProgress, Drawer, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import NumericLabel from 'react-pretty-numbers';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { LabelValueCard, RevisionHistory, PageLoader, CustomChip, CustomDropzone, PreviewDocsModal, DeletePopUp } from '../../../../shared_elements';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../../constants';
import { capitalizeFirstLetter, checkApiStatus, getCurrencyFormat, getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { getInsuranceApi, addEditInsuranceApi } from '../apiServices';
import { fieldValidation, dateRangeValidation, } from '../../../../utils_v2/formValidation';
import { errorCode, addInsurance } from '../';
import { regexConstants } from '../../../../constants/regEx';
import { addAttachmentApi, deleteAttachmentApi } from '../../../../shared_elements/apiServices';
class InsuranceCRU extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      formSubmitLoader: false,
      mode: '',
      insurance: {},
      pageLoader:false,
      error: {},
      previewDocs: '',
      deleteAttachmentModal: false, 
      attachedDoc:[]
    }
    this.addEditInsuranceApi = addEditInsuranceApi.bind(this);
    this.getInsuranceApi = getInsuranceApi.bind(this);
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      insurance: {
        ...prevState.insurance,
        [keyParam]: data
      }
    }))
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]: message
      }
    }))
  }
  toggleFnc = (key,status)=>{
    this.setState({[key] : status})
  }
  getDeleteResponse = () => {
    const { insurance, deleteItem } = this.state
    const updatedAttachments = insurance?.attachments?.filter(
      attachment => attachment.uuid !== deleteItem?.uuid
    );
    this.setState(prevState => ({
      ...prevState,
      insurance: {
        ...prevState.insurance,
        attachments: updatedAttachments
      }
    }))
    this.props.getResponseBack()
  }
  addEditInsuranceFn = () => {
    const { insurance } = this.state;
    let validationInputs = {
      insurance_type: errorCode['insurance_type'][fieldValidation({ ...errorCode['insurance_typeObj'], fieldval: insurance.insurance_type && insurance.insurance_type.value ? insurance.insurance_type.value.toString() : '' })],
      insurance_number: errorCode['insurance_number'][fieldValidation({ ...errorCode['insurance_numberObj'], fieldval: insurance.insurance_number })],
      insurer: errorCode['insurer'][fieldValidation({ ...errorCode['insurerObj'], fieldval: insurance.insurer })],
      insuree: errorCode['insuree'][fieldValidation({ ...errorCode['insureeObj'], fieldval: insurance.insuree })],
      policy_start_date: errorCode['policy_start_date'][fieldValidation({ ...errorCode['policy_start_dateObj'], fieldval: insurance.policy_start_date })],
      policy_end_date: errorCode['policy_end_date'][fieldValidation({ ...errorCode['policy_end_dateObj'], fieldval: insurance.policy_end_date })],
      insurance_amount: errorCode['insurance_amount'][fieldValidation({ ...errorCode['insurance_amountObj'], fieldval: insurance.insurance_amount })],
      effective_date: insurance?.effective_date ? moment(insurance?.effective_date).isValid()? '':'Please enter valid date'  : '',
    };
    if (insurance.policy_start_date && insurance.end_date) {
      validationInputs = {
        ...validationInputs,
        policy_start_date: dateRangeValidation(insurance.policy_start_date, insurance.policy_end_date, 30)
      }
    }
    if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
      if (this.state.attachedDoc?.length+insurance?.attachments?.length>50) {
        this.props.enqueueSnackbar('Only 50 attachment can be attached.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        return; 
      }
      let payload = Object.assign({}, insurance);
      payload = {
        ...payload,
        insurance_type: insurance.insurance_type.value
      }
      this.setState({formSubmitLoader:true});
      this.addEditInsuranceApi(this.props, payload)
        .then(response => {
          this.setState({formSubmitLoader:false});
          if (checkApiStatus(response)) {
            if (this.state.attachedDoc?.length > 0) {
              addAttachmentApi(response?.data, this.props, this.state.attachedDoc, "18", this.toggleFnc);
            } else {
              this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
              this.props.getResponseBack();
            }
            this.setState({ modal: false, mode: '', insurance: {}, error: {} })
          } else {
            this.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          }
        })

    } else {
      this.setState({ error: validationInputs });
    }
  }
  render() {
    const { modal, mode, formSubmitLoader, insurance, error, pageLoader } = this.state;
    const { id, m, pltConstants, contractInfo } = this.props;
    return (
      <Fragment>
         { pageLoader ? <PageLoader />:null}
        {id ?
          <span onClick={() => this.getInsuranceApi(this.props, id, m)}><Tooltip title="View"><VisibilityIcon color='primary' fontSize='small' style={{cursor:'pointer'}}/></Tooltip></span>
          :
          contractInfo?.hdlineInfo?.archived_asset == false?
          // <Button color="primary" variant="contained" onClick={() => this.setState({ modal: true, insurance: addInsurance, mode: 'add' })}>Add Insurance</Button>
         <span onClick={() => this.setState({ modal: true, insurance: addInsurance, mode: 'add' })} style={{display:'flex', marginRight:'8px'}}>
          <AddCircleIcon color="primary" variant="contained" />
          Add
          </span>
  : null
       }
        {modal ?
          <Drawer
            open={modal}
            onClose={() => this.setState({ modal: false, mode: '', insurance: {}, error: {} })}
            // aria-labelledby="scroll-dialog-title"
            anchor="right"
          >
            <div className="drawer-container" style={{ width: '670px' }}>
              <div className="drawer-header">
                <span className="left-blk">{`${capitalizeFirstLetter(mode)} Insurance`}</span>
                <CloseIcon className="close-icon" onClick={() => this.setState({ modal: false })} />
                {mode !== 'add' ?
                  <ul className="list-inline right-blk">
                    <li className="list-inline-item">
                      <RevisionHistory
                        url='audit/contracts/ctinsurances/'
                        queryParams={{ action: 1, object_id: id }}
                        buttonType={false}
                      />
                    </li>
                    <li className="list-inline-item">
                      { contractInfo?.hdlineInfo?.archived_asset == false? checkPermission('contracts','insurance', 'U') && mode === 'view' && <span className="cta" onClick={() => this.setState({ mode: 'edit' })}><EditIcon className="edit-icon" />Edit</span>: null}
                      {mode !== 'view' && <span className="cta" onClick={() => this.setState({ mode: 'view' })}><VisibilityIcon className="view-icon" />View</span>}
                    </li>
                  </ul> : null
                }
              </div>
              <div className="drawer-body" style={{position : "sticky"}}>
                <Grid container spacing={3}>
                  {mode === 'view' ?
                    <LabelValueCard md={6} label='Insurance Type' value={insurance.insurance_type && insurance.insurance_type.label ? insurance.insurance_type.label : '--'} /> :
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        options={pltConstants.filter(item => item.type === 'insurance_type')}
                        getOptionLabel={option => option.label}
                        id="insurance_type"
                        value={insurance.insurance_type ? insurance.insurance_type : null}
                        onFocus={() => this.updateErrorField('insurance_type', '')}
                        onChange={(e, value) => this.onFieldChange(e, 'insurance_type', value)}
                        renderInput={params => <TextField required error={error.insurance_type ? true : false} helperText={error.insurance_type ? error.insurance_type : ''} {...params} label="Insurance Type" placeholder="Select Insurance Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                      />
                    </Grid>
                  }

                  {mode === 'view' ?
                    <LabelValueCard md={6} label='Insurance Number' value={insurance.insurance_number ? insurance.insurance_number : '--'} /> :
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="insurance_number"
                        label="Insurance Number"
                        fullWidth
                        margin="normal"
                        value={insurance.insurance_number ? insurance.insurance_number : ''}
                        onChange={(e, value) => this.onFieldChange(e, 'insurance_number', e.target.value)}
                        onFocus={() => this.updateErrorField('insurance_number', '')}
                        error={error.insurance_number ? true : false}
                        helperText={error.insurance_number ? error.insurance_number : ''}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 30 }}
                      />
                    </Grid>
                  }

                  {mode === 'view' ?
                    <LabelValueCard md={6} label='Insurer' value={insurance.insurer ? insurance.insurer : '--'} /> :
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="insurer"
                        label="Insurer"
                        fullWidth
                        margin="normal"
                        value={insurance.insurer ? insurance.insurer : ''}
                        onChange={(e, value) => this.onFieldChange(e, 'insurer', e.target.value)}
                        onFocus={() => this.updateErrorField('insurer', '')}
                        error={error.insurer ? true : false}
                        helperText={error.insurer ? error.insurer : ''}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 30 }}
                      />
                    </Grid>
                  }

                  {mode === 'view' ?
                    <LabelValueCard md={6} label='Insuree' value={insurance.insuree ? insurance.insuree : '--'} /> :
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="insuree"
                        label="Insuree"
                        fullWidth
                        margin="normal"
                        value={insurance.insuree ? insurance.insuree : ''}
                        onChange={(e, value) => this.onFieldChange(e, 'insuree', e.target.value)}
                        onFocus={() => this.updateErrorField('insuree', '')}
                        error={error.insuree ? true : false}
                        helperText={error.insuree ? error.insuree : ''}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 30 }}
                      />
                    </Grid>
                  }

                  {mode === 'view' ?
                    <LabelValueCard md={6} label='Policy Start Date' value={insurance.policy_start_date ? moment(insurance.policy_start_date).format(displayDateFormatShort) : '--'} /> :
                    <Grid item xs={12} md={6}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          required
                          margin="normal"
                          id="policy_start_date"
                          label="Policy Start Date"
                          format={fieldDateFormat}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          value={insurance.policy_start_date ? insurance.policy_start_date : null}
                          onChange={(data, value) => this.onFieldChange(value, 'policy_start_date', moment(data).format(backendDateFormat))}
                          onFocus={() => this.updateErrorField('policy_start_date', '')}

                          error={error.policy_start_date ? true : false}
                          helperText={error.policy_start_date ? error.policy_start_date : ''}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  }

                  {mode === 'view' ?
                    <LabelValueCard md={6} label='Policy End Date' value={insurance.policy_end_date ? moment(insurance.policy_end_date).format(displayDateFormatShort) : '--'} /> :
                    <Grid item xs={12} md={6}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          required
                          margin="normal"
                          id="policy_end_date"
                          label="Policy End Date"
                          format={fieldDateFormat}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          value={insurance.policy_end_date ? insurance.policy_end_date : null}
                          onChange={(data, value) => this.onFieldChange(value, 'policy_end_date', moment(data).format(backendDateFormat))}
                          onFocus={() => this.updateErrorField('policy_end_date', '')}

                          error={error.policy_end_date ? true : false}
                          helperText={error.policy_end_date ? error.policy_end_date : ''}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  }

                  {mode === 'view' ?
                    <LabelValueCard md={6} label='Geo Limit' value={insurance.geo_limit ? insurance.geo_limit : '--'} /> :
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="geo_limit"
                        label="Geo Limit"
                        fullWidth
                        margin="normal"
                        value={insurance.geo_limit ? insurance.geo_limit : ''}
                        onChange={(e, value) => this.onFieldChange(e, 'geo_limit', e.target.value)}
                        onFocus={() => this.updateErrorField('geo_limit', '')}
                        error={error.geo_limit ? true : false}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 30 }}
                      />
                    </Grid>
                  }

                  {mode === 'view' ?
                    <LabelValueCard md={6} label='Insurance Amount' value={insurance.insurance_amount ? <NumericLabel params={getCurrencyFormat()}>{insurance.insurance_amount}</NumericLabel> : '--'} /> :
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="insurance_amount"
                        label="Insurance Amount"
                        fullWidth
                        margin="normal"
                        value={insurance.insurance_amount ? insurance.insurance_amount : ''}
                        onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'insurance_amount', e.target.value) : e.preventDefault() }}
                        onFocus={() => this.updateErrorField('insurance_amount', '')}
                        error={error.insurance_amount ? true : false}
                        helperText={error.insurance_amount ? error.insurance_amount : ''}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 10 }}
                      />
                    </Grid>
                  }

                  {mode === 'view' ?
                    <LabelValueCard md={6} label='Effective Date' value={insurance.effective_date ? moment(insurance.effective_date).format(displayDateFormatShort) : '--'} /> :
                    <Grid item xs={12} md={6}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          margin="normal"
                          id="effective_date"
                          label="Effective Date"
                          format={fieldDateFormat}
                          fullWidth
                          error={error.effective_date ? true : false}
                          helperText={error.effective_date ? error.effective_date : ''}
                          onFocus={() => this.updateErrorField('effective_date','')}
                          InputLabelProps={{ shrink: true }}
                          value={insurance.effective_date ? insurance.effective_date : null}
                          onChange={(data, value) => this.onFieldChange(value, 'effective_date', moment(data).format(backendDateFormat))}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  }

                  {mode === 'view' ?
                    <LabelValueCard md={6} label='Primary Contact' value={insurance.primary_contact ? insurance.primary_contact : '--'} /> :
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="primary_contact"
                        label="Primary Contact"
                        fullWidth
                        margin="normal"
                        value={insurance.primary_contact ? insurance.primary_contact : ''}
                        onChange={(e, value) => this.onFieldChange(e, 'primary_contact', e.target.value)}
                        error={error.primary_contact ? true : false}
                        helperText={error.primary_contact ? error.primary_contact : ''}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 30 }}
                      />
                    </Grid>
                  }
                  {mode === 'view' ?
                    <LabelValueCard md={12} label='Remarks' value={insurance.remarks ? ( <span dangerouslySetInnerHTML={{ __html: insurance.remarks.replace(/\n/g, '<br />')}}/>) : '--'} /> :
                    <Grid item xs={12}>
                      <TextField
                        id="remarks"
                        label="Remarks"
                        fullWidth
                        margin="normal"
                        multiline
                        rows="6"
                        value={insurance.remarks ? insurance.remarks : ''}
                        onChange={(e, value) => this.onFieldChange(e, 'remarks', e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        // inputProps={{ maxLength: 255 }}
                      />
                    </Grid>
                  }
                  {mode === 'view' ?
                    <div style={{ marginLeft: '8px', marginBottom: '20px' }}>
                      <p style={{ fontSize: '14px', color: 'rgb(160, 160, 160)', fontFamily: "Conv_IBMPlexSans-Regular" }}>Attachment(s) [Count: {insurance?.attachments?.length}]</p>
                      {insurance?.attachments?.length ? insurance?.attachments && insurance?.attachments.map((file, index) => (
                        <CustomChip
                          style={{ marginRight: '4px', marginTop: '7px'}}
                          title={file.name}
                          index={index}
                          label={file.name.substring(0, 14) + '...'}
                          onClick={(e) => { e.preventDefault(); e.stopPropagation();  this.setState({ previewDocs: file }) }}
                        />
                      )) : '--'}
                    </div>
                    :
                    (
                      <Grid item xs={12} style={{ marginBottom: '5px' }} className='drag-drop'>
                        <p style={{ fontSize: '13px', color: 'rgb(160, 160, 160)', fontFamily: "Conv_IBMPlexSans-Regular", marginLeft: '8px' }}>Attachment(s)</p>
                        <CustomDropzone
                          onChange={(files) => this.setState({ attachedDoc: files })}
                          componentData={insurance}
                          attachedDoc = {this.state.attachedDoc}
                        >
                        </CustomDropzone>
                        {insurance?.attachments && insurance?.attachments.map((file, index) => (
                          <CustomChip
                            style={{ marginRight: '4px', marginTop: '7px'}}
                            title={file.name}
                            index={index}
                            label={file.name.substring(0, 14) + '...'}
                            onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ previewDocs: file })}}
                            onDelete={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ deleteAttachmentModal: true, deleteItem: file }); }}
                          />
                        ))}
                      </Grid>)}
                </Grid>
              </div>
              <div className="drawer-footer">
                { mode !== 'view' ?
                  <Button onClick={this.addEditInsuranceFn} color="primary" variant="contained" disabled={formSubmitLoader}>
                    { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save Changes' }
                  </Button>:null
                }
                <Button onClick={() => this.setState({modal:false, mode:'', insurance:{}, error:{}})} color="primary" style={mode == 'view' ? {marginLeft:'auto', paddingLeft:'0px'}:{marginLeft:'8px'}}>Cancel</Button>
              </div>
            </div>
          </Drawer> : null
        }
        {
          this.state.previewDocs ? <PreviewDocsModal previewDocs={this.state.previewDocs} toggleModalFn={() => this.setState({ previewDocs: '' })} /> : null
        }
        {this.state.deleteAttachmentModal ?
          <DeletePopUp
            modal={this.state.deleteAttachmentModal}
            toggleModalFn={() => this.setState({ deleteAttachmentModal: false })}
            title="Delete Attachment"
            content={<h4>Are you sure you want to delete?</h4>}
            deleteRecordFn={() => deleteAttachmentApi(this.state.deleteItem,this.toggleFnc,this.getDeleteResponse)}
          /> : null
        }
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(InsuranceCRU));
