import { globalGetService, globalExportService, globalPostService, globalPutService } from '../../utils_v2/globalApiServices';
import { checkApiStatus, downloadFileType } from '../../utils_v2';
import { trackActivity } from '../../utils_v2/mixpanel';
export function getUtilsInitiateApi(props){
  globalGetService(`contracts/${props.params.contract_slug}/utilizations/initialize/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({utilsInitiate:response.data.data})
    }
  })
}

export function getUtilizationTrendsApi(props){
  globalGetService(`contracts/${props.params.contract_slug}/utilizations/trend/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({utilsTrends: response.data.data});
    }
  })
}

export function getAvgUtilizationApi(props){
  globalGetService(`contracts/${props.params.contract_slug}/utilizations/average/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({utlisAverage:response.data.data})
    }
  })
}
export function exportAvgUtilizationApi(props, query){
  globalExportService(`/contracts/${props.params.contract_slug}/utilization/`,query)
  .then(response => {

  })
}

export function getFleetMRUtilsApi(props, query, loaderType, dataType){
  this.setState({[loaderType]:true});
  let filterQuery = Object.assign({}, query);
  globalGetService(`contracts/v2/list-utilization/`, filterQuery)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      delete query.page;
      delete query.per_page;
      delete query.sort;
      delete query.sort_by;
      this.setState({
        utils:response.data.data,
        filter:query
      });
      if(dataType){
        this.setState({open:true});
      }
    }
  })
}

export function getMRUtilizationListApi(props, query){
  globalGetService(`contracts/${props.params.contract_slug}/utilization/`,query)
  .then(response => {
    if(checkApiStatus(response)){
      let result = Object.assign({}, response.data.data);
      result = {
        ...result,
        pagination:{page: response.data.data.pagination.current_page, per_page:response.data.data.pagination.per_page?response.data.data.pagination.per_page:10, total:response.data.data.pagination.total}
      }
      this.setState({mrUtils:result});
    }
  })
}
export function getMRUtilizationApi(props, id){
  globalGetService(`contracts/${props.params.contract_slug}/utilization/${id}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        modal:true,
        mode:'edit',
        mrUtilsCrud:response.data.data,
        error:{}
      });
    }
  })
}
export function editMRUtilizationApi(props, data){
  globalPutService(`contracts/${props.params.contract_slug}/utilization/${data.id}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        modal:false,
        mode:'',
        mrUtilsCrud:{},
        error:{}
      });
    }
  })
}

export function getRentalUtilizationListApi(props, query){
  globalGetService(`contracts/${props.params.contract_slug}/rental-utilization/`,query)
  .then(response => {
    if(checkApiStatus(response)){
      let result = Object.assign({}, response.data.data);
      result = {
        ...result,
        pagination:{page: response.data.data.pagination.current_page, per_page:response.data.data.pagination.per_page?response.data.data.pagination.per_page:10, total:response.data.data.pagination.total}
      }
      this.setState({rentalUtilis:result})
    }
  })
}

export function addMRUtilizationApi(props, data){
  globalGetService(`contracts/${props.params.contract_slug}/utilization/`, data)
  .then(response => {
    if(checkApiStatus(response)){

    }
  })
}

export function addEditRentalUtilsApi(props, data){
  return new Promise(function(resolve, reject){
    if(data.id){
      globalPutService(``,data)
      .then(response => {
        if(checkApiStatus(response)){
          resolve(response)
        }
      })
    }else{
      globalPostService(``,data)
      .then(response => {
        if(checkApiStatus(response)){
          resolve(response)
        }
      })
    }
  })
}

export function getMajorAssembliesApi(props){
  globalGetService(`contracts/${props.params.contract_slug}/major-assemblies/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({majorAssemblies:response.data.data});
    }
  })
}

export function exportRentalUtilizationApi(props, query){
  globalGetService(`contracts/${props.params.contract_slug}/rental-utilization/`, query)
  .then(response => {

  })
}
export function exportMrUtilizationApi(props, query){
  globalGetService(`contracts/${props.params.contract_slug}/rental-utilization/`, query)
  .then(response => {

  })
}
export function exportFleetUtilsApi(props, query){
  this.setState({pageLoader:true});
  let filterQuery = Object.assign({}, query);
  globalExportService(`contracts/v2/list-utilization/`, filterQuery)
  .then(response => {
    this.setState({pageLoader:false});
    trackActivity('Lease Management', {
      page_title: 'Fleet Utilizations List',
      event_type: 'File Exported',
      activity_type:'Report sent to email',
      file_type: filterQuery.download
    })
    this.props.enqueueSnackbar("Request Queued!! You'll receive the requested file on your registered email shortly.", {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
  })
}

export function getAssetTypeGraphApi(props={}, genericFilter, query, loaderType){
  this.setState({[loaderType]:true});
  let filterQuery = {...genericFilter, ...query};
  globalGetService(`contracts/v2/dashboard/utilization-asset-types/`, filterQuery)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      this.setState({
        assetTypeGraphData: response.data.data,
        filterAssetType:query
      })
    }
  })
}

export function getMonthWiseGraphApi(props={}, genericFilter, query, loaderType){
  this.setState({[loaderType]:true});
  let filterQuery = {...genericFilter, ...query};
  globalGetService(`contracts/v2/dashboard/utilization-monthly-track/`, filterQuery)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      this.setState({
        monthWiseGraphData: response.data.data,
        filterMonthWise:query
      })
    }
  })
}

export function getYearWiseGraphApi(props={}, genericFilter, query, loaderType){
  this.setState({[loaderType]:true});
  let filterQuery = {...genericFilter, ...query};
  globalGetService(`contracts/v2/dashboard/utilization-yearly-track/`, filterQuery)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      this.setState({
        yearWiseGraphData: response.data.data,
        filterYearWise:query
      })
    }
  })
}

export function getLesseeGraphApi(props={}, genericFilter, query, loaderType){
  this.setState({[loaderType]:true});
  let filterQuery = {...genericFilter, ...query};
  globalGetService(`contracts/v2/dashboard/utilization-lessee/`, filterQuery)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      this.setState({
        lesseeGraphData: response.data.data,
        filterUtilsLessee:query
      })
    }
  })
}

export function getAvgUtilsGraphApi(props={}, genericFilter, query, loaderType){
  this.setState({[loaderType]:true});
  let filterQuery = {...genericFilter, ...query};
  globalGetService(`contracts/v2/dashboard/fleet-lease-annual-utilization/`, filterQuery)
  .then(response => {
    this.setState({[loaderType]:false});
    if(checkApiStatus(response)){
      this.setState({
        avgUtilsGraphData: response.data.data,
        filterAvgUtils:query
      })
    }
  })
}

export function getAssetIdentifierApi(assetIdentifier, stateType, query={}){
  globalGetService(`contracts/v2/dashboard/lease-generic-filters/${assetIdentifier}/`,query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({[stateType]:response.data.data})
    }
  })
}
export function getLesseeFilterApi(query){
  globalGetService(`console/lessees/`, query)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({lesseeFilter:response.data.data?.lessees})
    }
  })
}


